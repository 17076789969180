import React from 'react';
import { Box, Typography } from '@mui/material';
import Carousel from 'react-multi-carousel';

import bullet1 from '../../../assets/images/subscription/bullet_1.webp';
import bullet2 from '../../../assets/images/subscription/bullet_2.webp';
import bullet3 from '../../../assets/images/subscription/bullet_3.webp';
import bullet4 from '../../../assets/images/subscription/bullet_4.webp';
import mob_bottom_2 from '../../../assets/images/subscription/mob_bottom_2.webp';
import web_clone from '../../../assets/images/subscription/web_clone.webp';
import payment_options from '../../../assets/images/subscription/payment_options.webp';
import { Card } from '../../Main/components/Reviews';

import 'react-multi-carousel/lib/styles.css';
import ChoosePlan from './ChoosePlan';
import { Routes } from '../../../App';

function MainContent() {
  const carouselResponsive = {
    desktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: '460px',
          margin: 'auto',
          padding: '48px 20px 64px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Kalnia500',
            fontSize: '32px',
            color: '#fff',
            marginBottom: '32px',
          }}
        >
          Understand Yourself and Improve Relationships With Astrology
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '24px',
            flexDirection: 'column',
            '& div': {
              display: 'flex',
              gap: '24px',
              '& img': {
                width: '64px',
                height: '80px',
              },
              '& p': {
                fontFamily: 'Sans400',
                fontSize: '20px',
                color: '#717889',
              },
            },
          }}
        >
          <Box>
            <img src={bullet1} alt="bullet"></img>
            <Typography>Know everything about yourself with ancient palm reading techniques</Typography>
          </Box>
          <Box>
            <img src={bullet2} alt="bullet"></img>
            <Typography>Predict every significant moment of your life and career</Typography>
          </Box>
          <Box>
            <img src={bullet3} alt="bullet"></img>
            <Typography>When will you meet your true love? When are you going to have a baby? Find it out</Typography>
          </Box>
          <Box>
            <img src={bullet4} alt="bullet"></img>
            <Typography>What makes you sad or happy? Everything in the world is interconnected</Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            fontFamily: 'Kalnia500',
            fontSize: '32px',
            color: '#fff',
            marginTop: '64px',
          }}
        >
          Join millions of our happy users
        </Typography>
        <Box
          sx={{
            margin: 'auto',
            position: 'relative',
            overflow: 'hidden',

            '@media (max-width: 500px)': {
              width: '390px',
            },
            '@media (max-width: 450px)': {
              width: '340px',
            },
          }}
        >
          <Box
            sx={{
              paddingBottom: '40px',
              margin: '24px 0 64px',
              '& .carousel': {
                '& li': {
                  transition: 'all ease 0.5s',
                },
              },
              '& .dots': {
                '& li>button': {
                  border: 'none',
                  background: '#30304B',
                  margin: '10px',
                },
                '& .react-multi-carousel-dot--active > button': {
                  background: '#E1413E',
                },
              },
            }}
          >
            <Carousel
              className="carousel"
              arrows={false}
              responsive={carouselResponsive}
              additionalTransfrom={0}
              showDots
              autoPlay
              centerMode={false}
              containerClass="container"
              dotListClass="dots"
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside
            >
              <Card cardNumber={1} isForCarousel></Card>
              <Card cardNumber={2} isForCarousel></Card>
              <Card cardNumber={3} isForCarousel></Card>
              <Card cardNumber={4} isForCarousel></Card>
              <Card cardNumber={5} isForCarousel></Card>
              <Card cardNumber={6} isForCarousel></Card>
            </Carousel>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: '#01091C',
          backgroundImage: `url(${web_clone}), url(${mob_bottom_2})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top, bottom',
          paddingBottom: '300px',
          padding: '359px 20px 300px',
          maxWidth: '460px',
          '@media (max-width: 430px)': {
            padding: '310px 20px 300px',
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Kalnia500',
            fontSize: '32px',
            color: '#fff',
            textAlign: 'center',
            marginBottom: '24px',
          }}
        >
          100% money-back guarantee
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Sans400',
            fontSize: '20px',
            color: '#717889',
            textAlign: 'center',
          }}
        >
          We believe that our plan may work for you and you’ll get visible results in 4 weeks! We even are ready to
          return your money back if you don’t see visible results and can demonstrate that you followed our plan.
          <br />
          <br />
          Find more about the applicable limitations in our{' '}
          <span
            style={{
              color: '#E1413E',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(Routes.BILLING_TERMS + '#money-back-policy', '_blank');
            }}
          >
            money-back policy
          </span>
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Kalnia500',
            fontSize: '20px',
            color: '#fff',
            paddingTop: '32px',
            textAlign: 'center',
            marginBottom: '16px',
          }}
        >
          Secure payments by
        </Typography>
        <Box>
          <img width={'100%'} src={payment_options} alt="options"></img>
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: '460px',
          margin: 'auto',
          padding: '48px 20px 20px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Sans400',
            fontSize: '20px',
            color: '#E1413E',
            textAlign: 'center',
            marginBottom: '16px',
          }}
        >
          Discover Your Future
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Kalnia500',
            fontSize: '24px',
            color: '#fff',
            textAlign: 'center',
            marginBottom: '32px',
          }}
        >
          Take advantage of your personalised plan and make today the day you start gaining all the insights that
          Astrology can offer.
        </Typography>
        <ChoosePlan hideSubscriptionTerms={true}></ChoosePlan>
      </Box>
    </>
  );
}

export default MainContent;
