import { Box } from '@mui/material';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider.hooks';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Step5() {
  const { setStep } = useQuizState();

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          setStep((prev) => prev - 1);
        }}
        stepNumber={5}
        stepsNumber={8}
      ></QuizHeader>
      <QuizQuestion text="Do you know your time of birth?"></QuizQuestion>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <QuizButton onClick={() => setStep((prev) => prev + 1)} variant={ButtonType.WHITE} text="Yes"></QuizButton>
          <QuizButton onClick={() => setStep((prev) => prev + 2)} variant={ButtonType.WHITE} text="No"></QuizButton>
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step5;
