import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import features1 from '../../../assets/images/main/features_image_1.webp';
import features2 from '../../../assets/images/main/features_image_2.webp';
import features3 from '../../../assets/images/main/features_image_3.webp';
import features4 from '../../../assets/images/main/features_image_4.webp';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../App';

function UnderstandYourself() {
  const isMobile = useMediaQuery('(max-width:800px)');
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: '#01091C',
      }}
    >
      <Box
        sx={{
          maxWidth: '1356px',
          margin: 'auto',
          padding: '0 20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Kalnia500',
              fontSize: isMobile ? '32px' : '48px',
              color: '#fff',
              maxWidth: isMobile ? '100%' : '682px',
              marginBottom: isMobile ? '32px' : '48px',
            }}
          >
            Understand Yourself and Improve Relationships With Astrology
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: isMobile ? '8px' : '32px',
            paddingBottom: isMobile ? '64px' : '140px',

            '& .card': {
              border: '1px solid #30304B',
              borderRadius: isMobile ? '16px' : '24px',
              height: isMobile ? '122px' : '388px',
              width: isMobile ? '100%' : 'calc(50% - 18px)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
              backgroundSize: 'contain',

              '@media (max-width: 1050px) and (min-width: 800px)': {
                backgroundPosition: '50px',
                backgroundSize: 'cover',
              },

              display: 'flex',
              flexDirection: 'column',
            },
            '& .title': {
              fontFamily: isMobile ? 'Sans400' : 'Kalnia500',
              color: isMobile ? '#717889' : '#fff',
              fontSize: isMobile ? '16px' : '32px',
              padding: isMobile ? '24px 0  4px 24px' : '48px 0 17px 48px',
            },
            '& .subTitle': {
              fontFamily: 'Sans400',
              color: '#717889',
              fontSize: '24px',
              maxWidth: '320px',
              padding: '0 48px',
              flexGrow: 1,
              textShadow: '-1px 0 #01091C, 0 1px #01091C, 1px 0 #01091C, 0 -1px #01091C',
              display: isMobile ? 'none' : undefined,
            },
            '& .link': {
              fontFamily: isMobile ? 'Kalnia500' : 'Kalnia600',
              color: '#fff',
              fontSize: isMobile ? '20px' : '24px',
              borderBottom: '2px solid #E1413E',
              paddingBottom: '8px',
              width: 'max-content',
              margin: isMobile ? '0 0 32px 24px' : '0 0 48px 48px',
              cursor: 'pointer',
            },
          }}
        >
          <Box
            className="card"
            sx={{
              backgroundImage: `url(${features1})`,
            }}
          >
            <Typography className="title">Palm Reading</Typography>
            <Typography className="subTitle">
              Know everything about yourself with ancient palm reading techniques*
            </Typography>
            <Typography
              className="link"
              onClick={() => {
                navigate(Routes.QUIZ);
              }}
            >
              Find out the fate
            </Typography>
          </Box>
          <Box
            className="card"
            sx={{
              backgroundImage: `url(${features2})`,
            }}
          >
            <Typography className="title">Horoscope</Typography>
            <Typography className="subTitle">Simply put, palmistry is the art of analyzing the physical*</Typography>
            <Typography
              className="link"
              onClick={() => {
                navigate(Routes.QUIZ);
              }}
            >
              Daily Prediction
            </Typography>
          </Box>
          <Box
            className="card"
            sx={{
              backgroundImage: `url(${features3})`,
            }}
          >
            <Typography className="title">Compatibility</Typography>
            <Typography className="subTitle">
              When will you meet your true love? When are you going to have a baby? Find it out*
            </Typography>
            <Typography
              className="link"
              onClick={() => {
                navigate(Routes.QUIZ);
              }}
            >
              Find Soulmate
            </Typography>
          </Box>
          <Box
            className="card"
            sx={{
              backgroundImage: `url(${features4})`,
            }}
          >
            <Typography className="title">Biorhythms</Typography>
            <Typography className="subTitle">
              What makes you sad or happy? Everything in the world is interconnected*
            </Typography>
            <Typography
              className="link"
              onClick={() => {
                navigate(Routes.QUIZ);
              }}
            >
              Know Yourself Better
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default UnderstandYourself;
