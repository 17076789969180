import React from 'react';
import { Box, Link, Typography, useMediaQuery } from '@mui/material';
import logo from '../../../assets/svg/logo.svg';
import mob_intro_bottom from '../../../assets/images/quiz/mob_intro_bottom.webp';
import { Routes } from '../../../App';
import { APP_CONSTANTS } from '../../../utils/constants';

function Links({ isMobile }: { isMobile: boolean }) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: isMobile ? '16px 32px' : '32px',
        flexWrap: 'wrap',
        '& .link': {
          fontFamily: 'Sans400',
          fontSize: '16px',
          color: '#fff',
          textDecoration: 'underline',
          transition: 'all 0.3s ease',
          whiteSpace: 'nowrap',
          '&:hover': {
            opacity: 0.8,
          },
        },
      }}
    >
      <Link target="_blank" href={Routes.PRIVACY} className="link">
        Privacy Policy
      </Link>
      <Link target="_blank" href={Routes.TERMS} className="link">
        Terms of Use
      </Link>
      <Link target="_blank" href={Routes.BILLING_TERMS} className="link">
        Billing Terms
      </Link>
      <Link href={`mailto:${APP_CONSTANTS.EMAIL}`} className="link">
        Contact Support
      </Link>
    </Box>
  );
}

function Copyright({ isMobile }: { isMobile: boolean }) {
  return (
    <Typography
      sx={{
        fontFamily: 'Sans400',
        fontSize: '16px',
        color: '#717889',
        textAlign: isMobile ? 'left' : 'right',
      }}
    >
      Copyright 2023 All Rights Reserved. {APP_CONSTANTS.APP_NAME} {APP_CONSTANTS.ADDRESS}
    </Typography>
  );
}

function Footer() {
  const isMobile = useMediaQuery('(max-width:800px)');

  return (
    <Box
      sx={{
        backgroundColor: '#01091C',
        backgroundImage: isMobile ? `url(${mob_intro_bottom})` : undefined,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
      }}
    >
      <Box
        sx={{
          maxWidth: '1356px',
          margin: 'auto',
          padding: '0 20px',
        }}
      >
        <Box
          sx={{
            padding: isMobile ? '64px 0 166px' : '48px 0',
            borderTop: '1px solid #30304B',
          }}
        >
          {isMobile ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
              }}
            >
              <Box>
                <img src={logo} alt="logo"></img>
              </Box>
              <Typography
                sx={{
                  fontFamily: 'Sans400',
                  fontSize: '16px',
                  color: '#717889',
                  textAlign: 'left',
                }}
              >
                *The application is designed for entertainment purposes ONLY.
              </Typography>
              <Copyright isMobile={isMobile}></Copyright>
              <Links isMobile={isMobile}></Links>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <img src={logo} alt="logo"></img>
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  gap: '16px',
                  marginLeft: '20px',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Sans400',
                    fontSize: '16px',
                    color: '#717889',
                    textAlign: 'right',
                  }}
                >
                  *The application is designed for entertainment purposes ONLY.
                </Typography>
                <Links isMobile={isMobile}></Links>
                <Copyright isMobile={isMobile}></Copyright>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
