import { Box } from '@mui/material';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider.hooks';
import { MainGoal } from '../../../providers/QuizProvider.types';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Step2() {
  const { setStep, setQuizData } = useQuizState();
  const handleClick = (type: MainGoal) => {
    setQuizData((prev) => ({
      ...prev,
      mainGoal: type,
    }));
    setStep((prev) => prev + 1);
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          setStep((prev) => prev - 1);
        }}
        stepNumber={2}
        stepsNumber={8}
      ></QuizHeader>
      <QuizQuestion text="What is your main goal that you would like to achieve?"></QuizQuestion>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <QuizButton
            onClick={() => handleClick(MainGoal.FIND_PARTNER)}
            variant={ButtonType.WHITE}
            text="Find my perfect partner"
          ></QuizButton>
          <QuizButton
            onClick={() => handleClick(MainGoal.UNDERSTAND_MYSELF)}
            variant={ButtonType.WHITE}
            text="Understand myself better"
          ></QuizButton>
          <QuizButton onClick={() => handleClick(MainGoal.BOTH)} variant={ButtonType.WHITE} text="Both"></QuizButton>
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step2;
