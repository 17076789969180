import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import logo from '../../../assets/svg/logo.svg';
import desktop_main_bg from '../../../assets/images/main/desktop_main_bg.webp';
import mob_main_bg from '../../../assets/images/main/mob_main_bg.webp';
import features1 from '../../../assets/images/main/features1.svg';
import features2 from '../../../assets/images/main/features2.svg';
import features3 from '../../../assets/images/main/features3.svg';
import features4 from '../../../assets/images/main/features4.svg';
import StartTheQuizButton from '../../common/StartTheQuizButton';

function Header() {
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <Box
      sx={{
        backgroundColor: '#01091C',
        backgroundImage: isMobile ? `url(${mob_main_bg})` : `url(${desktop_main_bg})`,
        backgroundSize: isMobile ? 'contain' : 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: isMobile ? `top` : 'top',
      }}
    >
      <Box
        sx={{
          maxWidth: '1356px',
          margin: 'auto',
          padding: '0 20px',
        }}
      >
        <Box
          sx={{
            padding: isMobile ? '40px 0' : '50px 0',
          }}
        >
          <img src={logo} alt="logo"></img>
        </Box>
        <Typography
          sx={{
            fontFamily: 'Kalnia500',
            fontSize: isMobile ? '32px' : '64px',
            color: '#fff',
            maxWidth: isMobile ? '320px' : '920px',
          }}
        >
          Find peace of mind{!isMobile && <br />} and know yourself better
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Sans400',
            fontSize: isMobile ? '20px' : '24px',
            color: '#717889',
            maxWidth: '920px',
            padding: isMobile ? '24px 0' : '32px 0',
          }}
        >
          Unlock the secrets of your soul as you delve into the art of astrology, a timeless practice that has guided
          seekers for centuries. Take a short survey to see how we can help you.
        </Typography>

        <StartTheQuizButton isMobile={isMobile}></StartTheQuizButton>

        <Typography
          sx={{
            fontFamily: 'Kalnia500',
            fontSize: isMobile ? '20px' : '24px',
            color: '#fff',
            margin: isMobile ? '80px 0 24px' : '140px 0 32px',
          }}
        >
          Our key features
        </Typography>

        <Box
          sx={{
            borderTop: '1px solid #30304B',
            borderBottom: '1px solid #30304B',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? '0px' : '70px',
            padding: isMobile ? '20px 0' : 0,
            '& .wrapper': {
              width: '100%',
              margin: isMobile ? '20px 0' : '56px 0',
              display: isMobile ? 'flex' : 'block',
              alignItems: 'center',
              gap: isMobile ? '24px' : '0px',
            },
            '& .header': {
              color: '#FFF',
              fontFamily: 'Kalnia500',
              fontSize: isMobile ? '20px' : '24px',
              margin: isMobile ? '' : '24px 0 16px',
            },
            '& .subHeader': {
              color: '#717889',
              fontFamily: 'Sans400',
              fontSize: '20px',
            },
          }}
        >
          <Box className="wrapper">
            <Box>
              <img src={features1} alt="features"></img>
            </Box>
            <Box>
              <Typography className="header">Palm reading</Typography>
              <Typography className="subHeader">
                Know everything about yourself with ancient palm reading techniques*
              </Typography>
            </Box>
          </Box>
          <Box className="wrapper">
            <Box>
              <img src={features2} alt="features"></img>
            </Box>
            <Box>
              <Typography className="header">Horoscope</Typography>
              <Typography className="subHeader">Predict every significant moment of your life and career*</Typography>
            </Box>
          </Box>
          <Box className="wrapper">
            <Box>
              <img src={features3} alt="features"></img>
            </Box>
            <Box>
              <Typography className="header">Compatibility</Typography>
              <Typography className="subHeader">
                When will you meet your true love? When are you going to have a baby? Find it out*
              </Typography>
            </Box>
          </Box>
          <Box className="wrapper">
            <Box>
              <img src={features4} alt="features"></img>
            </Box>
            <Box>
              <Typography className="header">Biorhythms</Typography>
              <Typography className="subHeader">
                What makes you sad or happy? Everything in the world is interconnected*
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
