import { Box, Typography } from '@mui/material';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider.hooks';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import q5_image from '../../../assets/images/quiz/q5_image.webp';

function Step5No() {
  const { setStep } = useQuizState();

  return (
    <QuizStepBackgroundWrapper withoutBottom fullHeight>
      <QuizContainer>
        <Box
          sx={{
            height: '100dvh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              '& img': {
                width: '100%',
              },
            }}
          >
            <img src={q5_image} alt="heart"></img>
          </Box>
          <Typography
            sx={{
              fontFamily: 'Kalnia500',
              fontSize: '32px',
              color: '#fff',
              textAlign: 'center',
            }}
          >
            No problem! You can still find plenty of great insights.
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Sans400',
              fontSize: '20px',
              color: '#717889',
              textAlign: 'center',
              margin: '16px auto 24px',
              maxWidth: '350px',
            }}
          >
            Tip: If you find out later, you can adjust this in your profile settings.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <QuizButton onClick={() => setStep((prev) => prev - 2)} variant={ButtonType.BLACK} text="Back"></QuizButton>
            <QuizButton onClick={() => setStep((prev) => prev + 1)} variant={ButtonType.RED} text="Next"></QuizButton>
          </Box>
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step5No;
