import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import desktop_bg from '../../../assets/images/main/desktop_users_bg.webp';
import mob_bg from '../../../assets/images/main/mob_users_bg.webp';
import mob_bottom from '../../../assets/images/main/mob_users_bottom.webp';
import StartTheQuizButton from '../../common/StartTheQuizButton';

function NumberOfUsers() {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box
      sx={{
        minHeight: isMobile ? '840px' : '1870px',
        backgroundColor: '#01091C',
        backgroundImage: isMobile ? `url(${mob_bg})` : `url(${desktop_bg})`,
        backgroundSize: isMobile ? 'contain' : '2200px 1870px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: isMobile ? 'top' : 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: '1356px',
          margin: 'auto',
          padding: '0 20px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={{
            maxWidth: isMobile ? '100%' : '700px',
            marginTop: isMobile ? '700px' : '',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Kalnia500',
              fontSize: isMobile ? '32px' : '48px',
              color: '#fff',
              marginTop: '140px',
              '@media (max-width: 400px)': {
                marginTop: '70px',
              },
            }}
          >
            A large number of our users have already understood themselves and have found a path that will help them
            achieve results in life, career and family relationships
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Kalnia700',
              fontSize: isMobile ? '64px' : '72px',
              color: '#E1413E',
              marginTop: isMobile ? '20px' : '40px',
              marginBottom: '-10px',
              '@media (max-width: 400px)': {
                fontSize: '58px',
              },
            }}
          >
            9 564 753
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Sans400',
              fontSize: '20px',
              color: '#fff',
            }}
          >
            Our users become happier
          </Typography>

          <Typography
            sx={{
              fontFamily: 'Kalnia700',
              fontSize: isMobile ? '64px' : '72px',
              color: '#E1413E',
              marginTop: isMobile ? '20px' : '40px',

              marginBottom: '-10px',
              '@media (max-width: 400px)': {
                fontSize: '58px',
              },
            }}
          >
            278 965
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Sans400',
              fontSize: '20px',
              color: '#fff',
              marginBottom: '40px',
            }}
          >
            Couples found out about their compatibility
          </Typography>

          <StartTheQuizButton isMobile={isMobile}></StartTheQuizButton>
        </Box>
      </Box>
      {isMobile && (
        <Box
          sx={{
            padding: '84px 0 64px',
            backgroundColor: '#01091C',
          }}
        >
          <Box
            sx={{
              minHeight: '231px',
              backgroundImage: `url(${mob_bottom})`,
              backgroundSize: '100% 231px',
              backgroundRepeat: 'no-repeat',
            }}
          ></Box>
        </Box>
      )}
    </Box>
  );
}

export default NumberOfUsers;
