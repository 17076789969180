import { Box } from '@mui/material';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider.hooks';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import web_right_palm from '../../../assets/images/quiz/web_right_palm.webp';
import { useState } from 'react';
import TakePhoto from './TakePhoto';

function Step7() {
  const [isWebcamOpen, setWebcamOpen] = useState(false);
  const { setStep } = useQuizState();
  const handlePhoto = () => {
    setStep((prev) => prev + 1);
  };

  return (
    <>
      {isWebcamOpen ? (
        <TakePhoto setWebcamOpen={setWebcamOpen} onClick={handlePhoto}></TakePhoto>
      ) : (
        <QuizStepBackgroundWrapper withoutBottom>
          <Box
            sx={{
              height: '100dvh',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <QuizHeader
              onBack={() => {
                setStep((prev) => prev - 2);
              }}
              stepNumber={7}
              stepsNumber={8}
            ></QuizHeader>
            <QuizQuestion margin="16px 0 0" textAlign="center" text="Take a photo of your left palm"></QuizQuestion>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  maxWidth: '600px',
                  width: '100%',
                  display: 'flex',
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    backgroundImage: `url(${web_right_palm})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                ></Box>
              </Box>
            </Box>

            <QuizContainer>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <QuizButton
                  onClick={() => {
                    setWebcamOpen(true);
                  }}
                  variant={ButtonType.RED}
                  text="Take a photo"
                ></QuizButton>
                <Box
                  sx={{
                    height: '66px',
                    textAlign: 'center',
                    marginTop: '10px',
                    '& input': {
                      display: 'none',
                    },
                    '& label': {
                      fontFamily: 'Sans700',
                      fontSize: '20px',
                      transition: 'all 0.3s ease',
                      color: '#fff',
                      cursor: 'pointer',
                      '&:hover': {
                        opacity: 0.8,
                      },
                    },
                  }}
                >
                  <label>
                    Choose from gallery
                    <input onChange={handlePhoto} type={'file'} accept="image/png, image/jpeg"></input>
                  </label>
                </Box>
              </Box>
            </QuizContainer>
          </Box>
        </QuizStepBackgroundWrapper>
      )}
    </>
  );
}

export default Step7;
