import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import QuizHeader from '../../common/QuizHeader';
import { Routes } from '../../../App';
import QuizQuestion from '../../common/QuizQuestion';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider.hooks';
import { Relationship } from '../../../providers/QuizProvider.types';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Step1() {
  const navigate = useNavigate();
  const { setStep, setQuizData } = useQuizState();

  const handleClick = (type: Relationship) => {
    setQuizData((prev) => ({
      ...prev,
      relationship: type,
    }));
    setStep((prev) => prev + 1);
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          navigate(Routes.MAIN);
        }}
        stepNumber={1}
        stepsNumber={8}
      ></QuizHeader>
      <QuizQuestion text="So we can get to know you better, tell us about your relationship status."></QuizQuestion>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <QuizButton
            onClick={() => handleClick(Relationship.SINGLE)}
            variant={ButtonType.WHITE}
            text="Single"
          ></QuizButton>
          <QuizButton
            onClick={() => handleClick(Relationship.IN_RELATIONSHIP)}
            variant={ButtonType.WHITE}
            text="In a relationship"
          ></QuizButton>
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step1;
