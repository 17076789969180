import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Carousel from 'react-multi-carousel';

import StartTheQuizButton from '../../common/StartTheQuizButton';

import avatar_1 from '../../../assets/images/main/reviews/avatar_1.webp';
import avatar_2 from '../../../assets/images/main/reviews/avatar_2.webp';
import avatar_3 from '../../../assets/images/main/reviews/avatar_3.webp';
import avatar_4 from '../../../assets/images/main/reviews/avatar_4.webp';
import avatar_5 from '../../../assets/images/main/reviews/avatar_5.webp';
import avatar_6 from '../../../assets/images/main/reviews/avatar_6.webp';
import avatar_7 from '../../../assets/images/main/reviews/avatar_7.webp';
import avatar_8 from '../../../assets/images/main/reviews/avatar_8.webp';
import stars from '../../../assets/svg/stars.svg';

import 'react-multi-carousel/lib/styles.css';

function getAvatar(cardNumber: number) {
  const avatarArr = [avatar_1, avatar_2, avatar_3, avatar_4, avatar_5, avatar_6, avatar_7, avatar_8];
  return avatarArr[cardNumber - 1];
}
function getTexts(number: number) {
  const textsArr = [
    {
      name: 'Olivia S.',
      review: `A revelation! Thoughtful and inspiring guidance for self-discovery. If you're seeking clarity and a cosmic perspective, this is the place to be!`,
    },
    {
      name: 'Emily H.',
      review: `Truly transformative! Accurate and constructive horoscopes that have enhanced my self-awareness. A daily ritual that brings tangible improvements.`,
    },
    {
      name: 'Javier M.',
      review: `A beacon of positivity! Uplifting and accurate horoscopes that feel like a wise friend guiding me through life's cosmic dance. A cosmic gem!`,
    },
    {
      name: 'Alex R.',
      review: `Game-changer! Personalized and relatable horoscopes that have positively impacted my life. Like having a cosmic mentor always by my side.`,
    },
    {
      name: 'Sarah L.',
      review: `Life-changing accuracy! These horoscopes are my daily guide, offering profound insights and practical advice. Highly recommended!`,
    },
    {
      name: 'Michael B.',
      review: `In fact it has shown me my future all i need to do is to work hard. The app is really nice and it does not lie.`,
    },
    {
      name: 'Diana J.',
      review: `In fact it has shown me my future all i need to do is to work hard. The app is really nice and it does not lie.`,
    },
    {
      name: 'Diana J.',
      review: `In fact it has shown me my future all i need to do is to work hard. The app is really nice and it does not lie.`,
    },
  ];
  return textsArr[number - 1];
}

export function Card({ cardNumber, isForCarousel }: { cardNumber: number; isForCarousel?: boolean }) {
  const image = getAvatar(cardNumber);
  const texts = getTexts(cardNumber);
  return (
    <Box
      sx={{
        margin: '10px',
        pointerEvents: 'none',
        userSelect: 'none',
      }}
    >
      <Box
        sx={{
          minHeight: '450px',
          width: '100%',
          maxWidth: isForCarousel ? '375px' : '325px',
          borderRadius: '20px',
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          overflow: 'hidden',
          margin: 'auto',
        }}
      >
        <Box
          sx={{
            padding: '20px',
            background: 'linear-gradient(180deg, rgba(24, 24, 46, 0.00) 0%, #18182E 100%)',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Sans400',
              fontSize: '20px',
              color: '#fff',
              opacity: 0.7,
              marginBottom: '15px',
            }}
          >
            {texts.review}
          </Typography>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography
              sx={{
                fontFamily: 'Kalnia500',
                fontSize: '20px',
                color: '#fff',
              }}
            >
              {texts.name}
            </Typography>
            <img src={stars} alt="stars"></img>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function Reviews() {
  const isMobile = useMediaQuery('(max-width:1000px)');
  const isLess1250 = useMediaQuery('(max-width:1250px)');
  const carouselResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 1000, min: 800 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 800, min: 0 },
      items: 1,
    },
  };

  return (
    <Box
      sx={{
        backgroundColor: '#01091C',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          maxWidth: '1356px',
          margin: 'auto',
          padding: '0 20px',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            maxWidth: '682px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Kalnia500',
              fontSize: isMobile ? '32px' : '64px',
              color: '#fff',
            }}
          >
            Join millions of our happy users
          </Typography>
          {!isMobile && (
            <>
              <Typography
                sx={{
                  fontFamily: 'Sans400',
                  fontSize: '24px',
                  color: '#717889',
                  margin: '32px 0',
                }}
              >
                Many people around the world are grateful to us for our help in solving their everyday problems,
                relationships with loved ones and career growth. Join them now!
              </Typography>
              <StartTheQuizButton isMobile={isMobile}></StartTheQuizButton>
            </>
          )}
        </Box>
        {isMobile ? (
          <Box
            sx={{
              paddingBottom: '40px',
              margin: '24px 0 64px',
              '& .carousel': {
                overflow: 'visible',
                '& li': {
                  transition: 'all ease 0.5s',
                  maxWidth: '375px',
                },
              },
              '& .dots': {
                '& li>button': {
                  border: 'none',
                  background: '#30304B',
                  margin: '10px',
                },
                '& .react-multi-carousel-dot--active > button': {
                  background: '#E1413E',
                },
              },
            }}
          >
            <Carousel
              className="carousel"
              arrows={false}
              responsive={carouselResponsive}
              additionalTransfrom={0}
              showDots
              autoPlay
              centerMode={false}
              containerClass="container"
              dotListClass="dots"
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside
            >
              <Card cardNumber={1} isForCarousel></Card>
              <Card cardNumber={2} isForCarousel></Card>
              <Card cardNumber={3} isForCarousel></Card>
              <Card cardNumber={4} isForCarousel></Card>
              <Card cardNumber={5} isForCarousel></Card>
              <Card cardNumber={6} isForCarousel></Card>
            </Carousel>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              gap: '32px',
              marginTop: '-430px',
              maxHeight: '1200px',
              justifyContent: 'space-between',
              overflow: 'hidden',
              '&: after': {
                content: '""',
                width: 'calc(100% - 40px)',
                height: '516px',
                background: 'linear-gradient(180deg, rgba(1, 9, 28, 0.00) 0%, #01091C 100%)',
                position: 'absolute',
                bottom: 0,
              },
            }}
          >
            {!isLess1250 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '32px',
                  marginTop: '544px',
                }}
              >
                <Card cardNumber={3}></Card>
                <Card cardNumber={8}></Card>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                marginTop: isLess1250 ? '524px' : '684px',
              }}
            >
              <Card cardNumber={2}></Card>
              <Card cardNumber={7}></Card>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                marginTop: '342px',
              }}
            >
              <Card cardNumber={1}></Card>
              <Card cardNumber={6}></Card>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
              }}
            >
              <Card cardNumber={4}></Card>
              <Card cardNumber={5}></Card>
              <Card cardNumber={7}></Card>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Reviews;
