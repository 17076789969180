import { APP_CONSTANTS } from '../../utils/constants';

import * as S from './styles';

const Terms = (): JSX.Element => (
  <S.TermsContent>
    <S.TermsHeader>END USER LICENSE AGREEMENT</S.TermsHeader>
    <S.TermsDate>Last Updated: December 27, 2023</S.TermsDate>
    <S.TermsBody>
      <p>
        {APP_CONSTANTS.WEBSITE_NAME} website and Web application (collectively, “Service”) is a website owned and
        operated by the {APP_CONSTANTS.APP_NAME} having its registered office at {APP_CONSTANTS.ADDRESS} (hereinafter
        “we”, “us”, “{APP_CONSTANTS.APP_SHORT_NAME}” etc.). This Terms of Use Agreement (this “Agreement”) describes the
        terms according to which you may legally use the Service, and the content contained there.
      </p>
      <p>
        By your use of this site, you agree to be bound by the terms and conditions of this Agreement, including any
        policies and procedures incorporated by reference, including this Terms of Use Agreement and our Privacy Policy.
      </p>
      <p>
        You also represent and warrant that you have the legal authority to accept the Agreement. If you do not agree to
        all of the terms in this Agreement, you are not authorized to use or access the Service.
      </p>
      <p>
        In order to understand the rules of the Agreement clearly you shall familiarize yourself with the meaning of
        terms used in the Agreement. In this Agreement the following terms, when capitalized, shall have the following
        meanings:
      </p>
      <ol className={'secondary'} type="1">
        <li>
          <b>“Account”</b> – means a personal account of a user of the Services.
        </li>
        <li>
          <b>“Application”, “App”, “Service”</b> – means an application for a mobile device that We make available on
          our Website, as well as any services provided through the Application.
        </li>
        <li>
          <b>“Company”, “We”</b> – means {APP_CONSTANTS.APP_NAME} having its registered office at{" "}
          {APP_CONSTANTS.ADDRESS}.
        </li>
        <li>
          <b>“Content”</b> – means all kinds of content, including, among other, data, texts, images, videos, sounds,
          links, software and other pieces of information in any form, which are made available to You through the App.
        </li>
        <li>
          <b>“Device”</b> – means a smartphone, a tablet or another similar mobile device as well as a computer that You
          either own or otherwise legally control for Your personal non-commercial use.
        </li>
        <li>
          <b>“Intellectual Property Rights”</b> – means, collectively, rights under any patent, trademark, copyright and
          trade secret laws, and any other intellectual property or proprietary rights recognized in any country or
          jurisdiction worldwide, including, without limitation, moral or similar rights.
        </li>
        <li>
          <b>“Subscription”</b> – means a period, during which You use the App as a whole or its certain additional
          Content for a fee.
        </li>
        <li>
          <b>“User-generated Content”</b> – means any form of Content, including, among other, data, texts, images,
          videos, sounds, links, that have been posted by users to online platforms, for example, social networks (e.g.
          Instagram, Facebook).
          <b>“You”</b> – means an individual using the App.
        </li>
      </ol>
      <li>
        <span className="main-list">1. CHANGES AND MODIFICATIONS</span>
        <ol className={'secondary'} type="1">
          <li>
            We may change or amend this Agreement from time to time. If we make material changes, we will endeavor to
            notify you that our terms have changed, either through the user interface, in an email message, or through
            other reasonable means. Your use of the Service after the date such change(s) become effective will
            constitute your consent to the changed terms.
          </li>
          <li>
            If you do not agree to the change(s), you must immediately stop using the Service; otherwise, the new terms
            will apply to you.
          </li>
          <li>
            As long as you comply with this Agreement, we grant you a limited, revocable, non-exclusive, non-assignable,
            non-sublicensable right to access and use the Service as it is intended to be accessed and used, and in
            accordance with this Agreement and applicable law. We grant you no other rights, implied or otherwise.
          </li>
        </ol>
      </li>
      <li>
        <span className="main-list">2. LEGAL CAPACITY AND AGE</span>
        <ol className={'secondary'} type="1">
          <li>
            The Service is intended for adults only. You must be 16 years of age or older to use the Service. If You
            install and/or use the Service, You confirm that You are at least 16 years old and have full legal capacity
            to enter into the Agreement.
          </li>
          <li>
            In accordance with the Federal Children’s Online Privacy Protection Act of 1998 (“COPPA”), we will never
            knowingly solicit, nor will we accept, personally identifiable information from users of the Service known
            to be under 13 years of age. Please see our privacy policy for additional information, including information
            regarding how to notify us of any concerns you may have in this regard.
          </li>
          <li>
            If You are between 16 and 18 years old, Your parent, guardian or other representative of legal age has to
            check and agree to this Agreement on Your behalf. If You install and/or use the Service, You confirm that
            Your parent, guardian or other representative of legal age has agreed to the terms of this Agreement and
            permits You to use the Service.
          </li>
        </ol>
      </li>
      <li>
        <span className="main-list">3. DEVICE</span>
        <ol className={'secondary'} type="1">
          <li>
            If You intend to download onto and/or run the Service on any Device not owned by You, You must have the
            owner’s permission to do so. You will be responsible for complying with this Agreement whether or not You
            own the Device.
          </li>
          <li>
            If You do not own the Device, You are allowed to install and/or use the App only through Your Account.
          </li>
          <li>
            If You use the Device together with other people, please, make sure that nobody except You can use the
            Service from Your Account. All actions taken in Your Account are considered Your actions in disregard of who
            actually takes such actions. You are fully responsible for all actions taken in Your Account.
          </li>
          <li>
            If You decide to sell or otherwise dispose of the Device, You shall delete the Application from the Device.
          </li>
        </ol>
      </li>
      <li>
        <span className="main-list">4. INTELLECTUAL PROPERTY</span>
        <ol className={'secondary'} type="1">
          <li>
            All text, metadata, user interfaces and visual interfaces (including graphics, photographs, sounds, music,
            and artwork), trademarks, logos, computer code, and any and all compilations thereof (collectively, “Our
            Content”), including but not limited to the design, structure, selection, coordination, expression, “look
            and feel,” and arrangement of Our Content, is owned, controlled, or licensed by or to Us, and is protected
            by trade dress, copyright, and trademark laws, and is subject to various other intellectual property rights.
          </li>
          <li>
            We hereby grant You a non-exclusive, personal, limited, revocable and non-transferable license to access,
            download and run the Service only on Your Device, provided that You agree to comply with all the terms and
            conditions of this Agreement. Please, take into consideration that the Service is licensed, not sold, to
            You.
          </li>
          <li>
            The Company or the licensor of the Company is the only holder of Intellectual Property Rights with regard to
            the Service. The Company retains all rights, title and interest in and to the Service and reserves all
            rights not expressly granted to You in this Agreement.
          </li>
          <li>
            You may not sell, resell, distribute, redistribute, rent, lease, sublicense, assign, copy, or in any other
            way transfer or grant any rights to the Service to any third party.
          </li>
          <li>
            You may not distribute or make the Service available over any network where it could be downloaded or used
            by multiple users at the same time, except when it is expressly authorized by Us.
          </li>
          <li>
            You may not use any automatic or manual device or process to interfere with the proper working of the
            Service. However, You may use any kind of device or process to delete the Service from Your Device.
          </li>
          <li>
            You may not decompile, disassemble, reverse engineer or create derivative works of the Service or any parts
            thereof. All modifications or enhancements to the Service remain the sole property of the Company.
          </li>
          <li>
            You may not use the Service in any unlawful manner, for any unlawful purpose, or in any manner inconsistent
            with this Agreement or act fraudulently or maliciously, for example, by hacking into or inserting malicious
            code, such as viruses, or harmful data, into the Service.
          </li>
        </ol>
      </li>
      <li>
        <span className="main-list">5. USES OF THE SERVICE</span>
        <p>You agree not to, and will not assist, encourage, or enable others to:</p>
        <ol className={'secondary'} type="1">
          <li>
            Upload, post, email, transmit, or otherwise make available any unsolicited or unauthorized advertising,
            promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” “affiliate links,” or any
            other form of solicitation, or any worms or viruses or any code of a destructive nature.
          </li>
          <li>Reverse engineer any portion of the Service.</li>
          <li>
            Use any robot, spider, site search/retrieval application, or other automated device, process, or means not
            provided by Us to access, retrieve, scrape, or index any portion of Service
          </li>
          <li>Record, process, or mine information about other users or about Service.</li>
          <li>
            Take any action that imposes, or may impose, in our sole discretion, an unreasonable or disproportionately
            large load on our technology infrastructure or otherwise make excessive traffic demands on Service.
          </li>
          <li>
            Interfere with the proper working of Service, circumvent or otherwise interfere with any security-related
            features of Service, or attempt to gain unauthorized access to Service, registered user accounts, or any
            computers/networks associated with Service through hacking, password mining, or any other means.
          </li>
          <li>
            Use the Service for any illegal or unauthorized purpose. You agree to comply with all applicable laws,
            including the laws of your local jurisdiction, regarding online conduct and acceptable content (including
            but not limited to intellectual property laws).
          </li>
        </ol>
      </li>
      <li>
        <span className="main-list">6. PURCHASING SUBSCRIPTION. TRIAL PERIOD</span>
        <ol className={'secondary'} type="1">
          <p>
            The App as a whole or its certain part (additional Content) is provided on a paid subscription basis. The
            fee may vary depending on the App, the period of the Subscription duration.
          </p>
          <p>
            <b>Direct Purchase from the Company.</b>
          </p>
          <p>
            You may purchase a Subscription directly from the Company, in which case it is the Company and the external
            payment service providers (Stripe, PayPal etc.), that collects money.
          </p>
          <p>
            The costs of all Subscriptions along with this ToS as well as Privacy Policy are available for your
            attention on the Website and web-page of the App before the purchase.
          </p>
          <p>When You purchase a Subscription, the payment is processed by a payment processing system you use.</p>
          <p>
            The digital content is made available to You immediately after the trial period begins or after the
            Subscription fee is charged.
          </p>
          <p>
            Please kindly note that we offer refunds in case of Direct Purchase from the Company (please see clause 16).
          </p>
          <p>
            Trial periods may be provided depending on the particular Subscription and the App you choose. You may use
            the App or its additional Content free of charge during this period. Please, take into consideration that at
            the end of the trial period it will automatically transform into a paid Subscription in accordance with
            clause 8 and Your Account will be charged for the cost of such Subscription, unless You cancel the
            Subscription through the subscription settings of Your Account at least 24 hours prior to the end of the
            free trial period.
          </p>
        </ol>
      </li>
      <li>
        <span className="main-list">7. AUTOMATIC RENEWAL OF SUBSCRIPTION. CANCELLATION OF SUBSCRIPTION</span>
        <ol className={'secondary'} type="1">
          <p>
            The Subscription will renew automatically for the same period at the end of the Subscription term at the
            cost of the chosen package. Your Account will be charged for the renewed Subscription within 24 hours prior
            to the end of the current Subscription. If You cancel automatic renewal within the last 24 hours of the
            Subscription, such cancellation will take effect at the end of the new Subscription.
          </p>
          <p>
            You may cancel renewal of the Subscription at least 24 hours before the end of the Subscription: in this
            case the App as a whole or its additional Content will be available to You until the end of the
            Subscription. After that the Subscription will not be renewed automatically.
          </p>
          <p>
            In order to cancel automatic renewal of the Subscription, please, change subscription settings of Your
            Account. After the end of the Subscription the App as a whole or its additional Content will be available
            only if a new Subscription is purchased.
          </p>
          <p>
            If You uninstall the App, Your Subscription will not stop automatically. You must cancel automatic renewal
            of Your Subscription in order not to be charged with the cost of the new Subscription.
          </p>
        </ol>
      </li>
      <li>
        <span className="main-list">8. RELATED SERVICES AND ADVICE</span>
        <ol className={'secondary'} type="1">
          <p>
            Some of Our Apps are devoted to astrology, horoscopes, palmistry and alike. Please, take into account that
            any kind of data provided to You through the App is provided for information purposes only. It is up to You
            whether to use this information as the basis for Your everyday activities and decisions. Under no
            circumstances shall We be responsible for any consequences of using such information as the basis for Your
            everyday activities and decisions.
          </p>
        </ol>
      </li>
      <li>
        <span className="main-list">9. LINKS TO EXTERNAL SITES AND SERVICES</span>
        <ol className={'secondary'} type="1">
          <li>
            The App may contain links to external sites and services (for example, applications for mobile devices). If
            You decide to visit and/or use such external sites and services, You do this at Your own risk. Under no
            circumstances shall We be responsible for the content of such sites and services. We cannot guarantee that
            these sites and services will be available for You or that Your use of such sites and services will be safe.
          </li>
          <li>
            This Agreement does not regulate Your relations with owners and/or administrators of such external sites and
            services. Please, check in terms of service, privacy policy or any other related document that regulates
            usage of external sites and services.
          </li>
        </ol>
      </li>
      <li>
        <span className="main-list">10. UPDATES</span>
        <ol className={'secondary'} type="1">
          <li>
            We may, though not obliged, update the App from time to time, making such updates, supplements or new
            versions of the App available to You. If We release an update (including a new version of the App) We may
            request You to update the App. The update may be downloaded and installed automatically or on the basis of
            Your consent depending on the updates settings of Your Device.
          </li>
          <li>
            The content of any update, supplement or new version of the App is determined exclusively by the Company.
            Under no circumstances do We accept any orders, requires or requests for further development of the App. We
            reserve the right to modify or discontinue any part, or function, or feature of the App at Our own
            discretion with or without a prior notice.
          </li>
          <li>
            If We introduce certain features or functions of the App, We may need to set certain rules for using such
            features or functions. In this case We will introduce changes to the ToS that will take effect on the day
            when such features or functions are released.
          </li>
          <li>
            We are committed to making Service usable and accessible to the widest possible audience, regardless of
            technology or ability. While We strive to ensure the accessibility of Service, you may find some
            limitations. Please report any problems to the support team at Service (
            <a href={`mailto:${APP_CONSTANTS.EMAIL}`}>{APP_CONSTANTS.EMAIL}</a>).
          </li>
        </ol>
      </li>
      <li>
        <span className="main-list">11. VIOLATION OF THIS AGREEMENT – TERMINATION</span>
        <ol className={'secondary'} type="1">
          <li>
            We may end Your rights to use the Service at any time by contacting You if You have broken the rules of
            these Agreement in a serious way. If what You have done can be put right We will give You a reasonable
            opportunity to do so.
          </li>
          <li>
            If We end Your rights to use the Service:
            <ol className="secondary dot-list">
              <li>You must stop all activities authorized by these Agreement, including Your use of the Service;</li>
              <li>
                You must delete or remove the Service from all Devices and immediately destroy all copies of the Service
                which You have and confirm to Us that You have done this.
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <span className="main-list">12. DISCLAIMER OF WARRANTIES</span>
        <ol className={'secondary'} type="1">
          <li>
            To the fullest extent permitted by applicable law, We and Our affiliates, officers, employees, agents,
            partners, and licensors expressly disclaim all warranties of any kind, express or implied, as to the
            accuracy, adequacy or completeness of any of the information contained in Our Service or created by the
            services.
          </li>
          <li>
            The Content in Our Service is provided to You on an “as is” basis, without any warranties as to
            merchantability or fitness for a particular purpose or use. We do not guarantee that You will attain any
            anticipated result from the use of the Service.
          </li>
          <li>
            We make no warranty that:
            <ol className="secondary dot-list">
              <li>the Service will meet your requirements;</li>
              <li>the Service will be uninterrupted, timely, secure, or error-free;</li>
              <li>content and material will be hosted and/or transmitted without interruption or cessation;</li>
              <li>
                any products, sites, information, or other material, whether in tangible or intangible form, downloaded
                or obtained by you through the service, will meet your expectations or any standard of quality;
              </li>
              <li>
                and any defects in the operation or functionality of the service or related software will be corrected.
              </li>
            </ol>
          </li>
          <li>
            Any service downloaded or otherwise accessed through the use of Our Service is at Your own discretion and
            risk and We shall have no responsibility or liability for any errors or omissions.
          </li>
          <li>
            We are not responsible for any damages, whether direct or indirect, special or consequential, suffered by
            You or by any other person from the use of the Service, even if You have been advised of the possibility of
            such damages.
          </li>
          <li>
            No advice or information, whether oral or written, obtained by You from Us or Our Service shall create any
            warranty not expressly stated in the Agreement. Under no circumstances shall Our liability or of any of Our
            affiliates pursuant to any cause of action, whether in contract, tort, or otherwise, exceed the fee paid by
            You for access to the Service. Furthermore, We shall have no responsibility or liability for delays or
            failures due to circumstances beyond Our control.
          </li>
          <li>
            Limitation of Liability:
            <ol className="secondary dot-list">
              <li>
                You expressly understand and agree that we and our affiliates will not be liable to you for any
                indirect, incidental, special, consequential, exemplary, or punitive damages that may be incurred by
                you, however caused and under any theory of liability.
              </li>
              <li>
                This includes, but is not limited to, any loss of profits, goodwill, or business reputation; any loss of
                data; any cost of procurement of substitute goods or services; or any other intangible losses. This also
                includes any loss or damages that may be incurred by you as a result of (a) any changes that we may make
                to the service ; (b) any permanent or temporary cessation of the service ; (c) the deletion or
                corruption of, or failure to store, any content or data (including without limitation user content,
                educational material, and metadata) maintained through the service ; or (d) your failure to keep your
                registered user account details secure.
              </li>
              <li>
                The limitations and exclusions of liability above shall apply irrespective of the theory of liability,
                including contract (including fundamental breach), warranty, product liability, strict liability, tort
                (including negligence), or other theory, even if we (or our affiliates) have been advised of the
                possibility of such damages.
              </li>
            </ol>
          </li>
          <li>
            Exclusions and Limitations. Some jurisdictions do not permit the exclusion of certain warranties or
            conditions, or the limitation or exclusion of liability for certain damages. Accordingly, only the
            limitations that are lawful in your jurisdiction will apply to you and, in such instances, our liability and
            that of our affiliates will be limited to the maximum extent permitted by law.
          </li>
          <li>
            Indemnification. You agree to defend, indemnify, and hold Us and Our Affiliates harmless from and against
            any and all liabilities and costs (including reasonable attorneys’ fees) we may incur in connection with any
            claim arising out of your breach of this Agreement or your use of the Service. We reserve the right, but
            have no obligation, to assume the exclusive defense and control of any matter otherwise subject to
            indemnification by you.
          </li>
          <li>
            Governing Law. These Terms (and any non-contractual disputes/claims arisiong out of or in connection with them)
            are subject to the laws of the state of Wyoming, United States of America, without regard to choice or conflicts
            of law principles.
          </li>
        </ol>
      </li>
      <li>
        <span className="main-list"> 13. TRANSFER OF RIGHTS</span>
        <ol className={'secondary'} type="1">
          <p>
            We may transfer Our rights and obligations under these ToS to any other organization or individual without
            Your consent. We will ensure that the transfer will not affect Your rights under the ToS.
          </p>
          <p>
            You may only transfer Your rights or Your obligations under these ToS to another person if We agree in
            writing.
          </p>
        </ol>
      </li>
      <li>
        <span className="main-list">14. YOUR VIOLATION OF THE TOS</span>
        <ol className={'secondary'} type="1">
          <p>
            We may end Your rights to use the App at any time by contacting You if You have broken the rules of these
            ToS in a serious way. If what You have done can be put right We will give You a reasonable opportunity to do
            so.
          </p>
          <p>If We end Your rights to use the App:</p>
          <ol className="secondary dot-list">
            <li>You must stop all activities authorized by these ToS, including Your use of the App;</li>
            <li>
              You must delete or remove the App from all Devices and immediately destroy all copies of the App which You
              have and confirm to Us that You have done this.
            </li>
          </ol>
        </ol>
      </li>
      <li id="money-back-policy">
        <span className="main-list">15. REFUND POLICY</span>
        <ol className={'secondary'} type="1">
          <li>
            <p>
              <b>Money-back guarantee rules</b>
            </p>
            Please kindly note that we offer refunds to our customers who made direct purchase from the Company. To
            request a refund please e-mail a written application to{' '}
            <a href={`mailto:${APP_CONSTANTS.EMAIL}`}>{APP_CONSTANTS.EMAIL}</a>
            containing the following information:
            <ol className="secondary dot-list">
              <li>Name and Surname of the customer, who purchased the services;</li>
              <li>Payment method (PayPal, Stripe, Debit Card, etc. used to perform a purchase);</li>
              <li>Date of the payment;</li>
              <li>Payment receipt or any payment confirmation;</li>
              <li>Brief justification of the reason for the need to a refund.</li>
            </ol>
            <p>
              <b>
                PLEASE BE INFORMED THAT YOUR REFUND REQUEST SHALL CONTAIN ALL THE INFORMATION DESCRIBED BY THE PRESENT
                CLAUSE 12.2 A-E IN ORDER TO ALLOW US TO PERFORM A REFUND. THE LACK OF INFORMATION FROM YOUR SIDE IN YOUR
                REQUEST WILL LEAD TO A LACK OF TECHNICAL ABILITY TO MAKE A REFUND PAYMENT. IN CASE YOUR REFUND REQUEST
                DOES NOT CONTAIN ALL THE INFORMATION REQUIRED BY THE PRESENT CLAUSE WE WILL BE UNABLE TO PERFORM A
                REFUND.
              </b>
            </p>
            <p>
              We will review your application and notify you (by email) whether your application is approved. If the
              application is approved, your refund will be processed, and a credit will be automatically applied to your
              credit card or original method of payment, within 14 days.
            </p>
            <p>
              In order to cancel automatic renewal of the Subscription, please, change subscription settings of Your
              Account. After the end of the Subscription the App as a whole or its additional Content will be available
              only if a new Subscription is purchased.
            </p>
          </li>
          <li>
            <p>
              <b>Important statement.</b>
            </p>
            Please note that only fulfillment of the above requirements allows you to receive a complete refund. For the
            sake of clarity, this Refund Policy does not apply to any other instances.
          </li>
        </ol>
      </li>
      <li>
        <span className="main-list">16. MISCELLANEOUS</span>
        <ol className={'secondary'} type="1">
          <li>
            <b>Entire Agreement.</b> These Terms of Use contain the entire agreement between you and{' '}
            {APP_CONSTANTS.WEBSITE_NAME} regarding the use of the Service, and supersede any prior agreement between you
            and {APP_CONSTANTS.WEBSITE_NAME} on such subject matter. The parties acknowledge that no reliance is placed
            on any representation made but not expressly contained in these Terms of Use. You may also be subject to
            additional terms and conditions that may apply when you use or purchase other goods or services from us, our
            affiliates, or our parents or subsidiary entities.
          </li>
          <li>
            <b>Governing Law.</b> If any dispute, controversy or difference arises between You and the Company, it shall
            be settled amicably through negotiations. In case such dispute, controversy or difference cannot be settled
            amicably through negotiations within a thirty 30-day period it shall be shall be settled by the American
            Arbitration Association (the AAA) in accordance with its International Expedited Procedures. The number of
            arbitrators shall be one. The place of arbitration shall be New York, New York, the United States of
            America. The arbitration shall be held, and the award rendered, in English. The appointing authority shall
            be the AAA acting in accordance with the rules adopted by the AAA for this purpose. Each party (i.e. You and
            the Company) shall bear its own expenses, but the parties shall share equally the expenses of the
            arbitration tribunal.
          </li>

          <li>
            <b>Statute Of Limitations.</b> You agree that, regardless of any statute or law to the contrary, any claim
            under this Agreement must be brought within one (1) year after the cause of action arises, or such claim or
            cause of action is forever barred.
          </li>
          <li>
            <b>Severability of Terms.</b> Each of the paragraphs of these ToS operates separately. If any court or
            relevant authority decides that any of them is unlawful, the remaining paragraphs will remain in full force
            and effect.
          </li>
          <li>
            <b>Notices.</b> We may provide you with notices, including those regarding changes to the Agreement, by
            email, by regular mail, or by postings to Service If you have general questions or concerns regarding these
            Terms of Use, please contact us in writing at{' '}
            <a href={`mailto:${APP_CONSTANTS.EMAIL}`}>{APP_CONSTANTS.EMAIL}</a>
          </li>
        </ol>
      </li>
    </S.TermsBody>
  </S.TermsContent>
);

export default Terms;
