import { addDays, addMonths, addYears, format } from 'date-fns';

const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function generateNumberArray(length: number): Array<number> {
  return Array.from({ length }, (_, index) => index);
}

export const generateYearsArray = (year: number): Array<string> => {
  const years = [];
  const nowYear = new Date().getFullYear();

  for (let max = nowYear; max > year; max--) {
    years.push(String(max));
  }

  return years;
};

export const generateDayArray = (length: number): Array<string | number> =>
  Array.from({ length }, (_, index) => {
    return index + 1;
  });


export const getYearsOption = () =>
  generateYearsArray(1950).map((year) => ({
    value: year,
    label: year,
  }));

export const getDaysOption = (daysInMonth: number) =>
  generateDayArray(daysInMonth).map((day) => ({
    value: day,
    label: day,
  }));

export const getMonthsOption = () =>
  generateNumberArray(12).map((month, index) => ({
    value: String(month),
    label: Months[index],
  }));


export const TIME_FORMAT = [
  {
    value: 'AM',
    label: 'AM',
  },
  {
    value: 'PM',
    label: 'PM',
  },
];

export const getHourOptionList = () =>
  generateNumberArray(12).map((num) => {
    const hour = num + 1;
    return {
      value: hour,
      label: hour,
    };
  });

export const getMinuteOptionList = () =>
  generateNumberArray(60).map((num) => {
    const minute = num;
    return {
      value: minute,
      label: minute,
    };
  });

export function getZodiacSign(month: number, day: number) {
  if ((month === 3 && day >= 21) || (month === 4 && day <= 19)) {
    return "Aries";
  } else if ((month === 4 && day >= 20) || (month === 5 && day <= 20)) {
    return "Taurus";
  } else if ((month === 5 && day >= 21) || (month === 6 && day <= 20)) {
    return "Gemini";
  } else if ((month === 6 && day >= 21) || (month === 7 && day <= 22)) {
    return "Cancer";
  } else if ((month === 7 && day >= 23) || (month === 8 && day <= 22)) {
    return "Leo";
  } else if ((month === 8 && day >= 23) || (month === 9 && day <= 22)) {
    return "Virgo";
  } else if ((month === 9 && day >= 23) || (month === 10 && day <= 22)) {
    return "Libra";
  } else if ((month === 10 && day >= 23) || (month === 11 && day <= 21)) {
    return "Scorpio";
  } else if ((month === 11 && day >= 22) || (month === 12 && day <= 21)) {
    return "Sagittarius";
  } else if ((month === 12 && day >= 22) || (month === 1 && day <= 19)) {
    return "Capricorn";
  } else if ((month === 1 && day >= 20) || (month === 2 && day <= 18)) {
    return "Aquarius";
  } else {
    return "Pisces";
  }
}

export const endTrial = (trialUnit: string, trialLength: number) => {
  if (trialUnit.includes('day')) {
      const dateEnd = addDays(new Date(), trialLength);

      return format(dateEnd, 'MMMM d, yyyy');
  }

  if (trialUnit.includes('month')) {
      const dateEnd = addMonths(new Date(), trialLength);

      return format(dateEnd, 'MMMM d, yyyy');
  }

  if (trialUnit.includes('year')) {
      const dateEnd = addYears(new Date(), trialLength);

      return format(dateEnd, 'MMMM d, yyyy');
  }
};
