import { useNavigate } from 'react-router-dom';

import { Routes } from '../../App';
import QuizButton, { ButtonType } from './QuizButton';

function StartTheQuizButton({ isMobile }: { isMobile: boolean }) {
  const navigate = useNavigate();

  return (
    <QuizButton
      maxWidth={isMobile ? undefined : '336px'}
      onClick={() => {
        navigate(Routes.QUIZ);
      }}
      variant={ButtonType.START}
      text="Start the quiz"
    ></QuizButton>
  );
}

export default StartTheQuizButton;
