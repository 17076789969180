import { Box } from '@mui/material';
import React from 'react';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Footer from './components/Footer';

function Subscription() {
  return (
    <Box
      sx={{
        backgroundColor: '#000716',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ backgroundColor: '#01091C' }}>
        <Header></Header>
        <MainContent></MainContent>
        <Footer></Footer>
      </Box>
    </Box>
  );
}

export default Subscription;
