
import { styled } from 'styled-components';


export const Layout = styled('div')`
    width: 100%;
    padding: 30px;
    border: 1px solid red;
`;

export const TermsContent = styled('div')`
    max-width: 1396px;
    margin: 0 auto;
    padding: 30px;
    font-family: 'Sans400;


    @media (max-width: 640px) {
        padding: 10px 0;
    }
`;

export const TermsHeader = styled('h1')`
    text-align: center;
    display: block;
    font-size: 2em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
`;

export const TermsSubHeader = styled(TermsHeader)`
    font-size: 1.5em;
`;

export const TermsDate = styled('p')`
    text-align: start;
    font-style: italic;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 20px;
    font-size: 16px;
`;

export const TermsSearch = styled('div')`
    max-width: 1396px;
    margin: 0 auto;
    padding: 30px 20px 20px;
    font-family: 'Sans400;

    @media (max-width: 640px) {
        padding: 20px 20px 10px;
    }
`;

export const TermsBody = styled('ol')`
    display: block;
    list-style: none;
    margin: 0 auto;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding: 0 20px;
    counter-reset: listNumbering;

    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
    }

    li {
        &:first-child {
            padding-top: 0;
        }
        padding-top: 20px;
        counter-increment: listNumbering;
        display: list-item;
        text-align: -webkit-match-parent;
    }

    a {
        text-decoration: underline;
    }

    .main-list {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .secondary {
        padding-top: 10px;
        display: block;
        list-style-type: decimal;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
    }

    .third {
        padding-top: 0;
        display: block;
        list-style-type: decimal;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
    }

    .letter-list {
        list-style-type: lower-alpha;

        li {
            padding-top: 0;
            padding-bottom: 10px;
        }
    }

    .dot-list {
        list-style-type: disc;
        li {
            padding-top: 0;
            padding-bottom: 10px;
        }
    }

    .sub-secondary {
        list-style: none;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;

        & li {
            &:first-child {
                padding-top: 0;
            }
            padding-top: 5px;
            &:before {
                content: '—';
                position: relative;
                left: -5px;
            }
        }
    }

    .h-red {
        color: darkred;
    }
`;
