import { Box, Typography } from '@mui/material';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider.hooks';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import q6_image from '../../../assets/images/quiz/q6_image.webp';
import { QuizData } from '../../../providers/QuizProvider.types';

function getText(decision: QuizData['decisions'], zodiac: QuizData['zodiac']) {
  switch (decision) {
    case 'Heart': {
      return (
        <Box
          sx={{
            fontFamily: 'Sans400',
            fontSize: '20px',
            color: '#717889',
            textAlign: 'center',
            padding: '16px 0 24px',
          }}
        >
          Based on our data,{' '}
          <span
            style={{
              color: '#fff',
              fontFamily: 'Sans700',
            }}
          >
            44% of {zodiac}
          </span>{' '}
          people also make decisions with their{' '}
          <span
            style={{
              color: '#E1413E',
              fontFamily: 'Sans700',
            }}
          >
            Heart.
          </span>{' '}
          But don't worry, we'll consider that while creating your guidance plan.
        </Box>
      );
    }
    case 'Head': {
      return (
        <Box
          sx={{
            fontFamily: 'Sans400',
            fontSize: '20px',
            color: '#717889',
            textAlign: 'center',
            padding: '16px 0 24px',
          }}
        >
          Based on our data,{' '}
          <span
            style={{
              color: '#fff',
              fontFamily: 'Sans700',
            }}
          >
            39% of {zodiac}
          </span>{' '}
          people also make decisions with their{' '}
          <span
            style={{
              color: '#E1413E',
              fontFamily: 'Sans700',
            }}
          >
            Head.
          </span>{' '}
          But don't worry, we'll consider that while creating your guidance plan.
        </Box>
      );
    }
    case 'Both': {
      return (
        <Box
          sx={{
            fontFamily: 'Sans400',
            fontSize: '20px',
            color: '#717889',
            textAlign: 'center',
            padding: '16px 0 24px',
          }}
        >
          Based on our data, only the{' '}
          <span
            style={{
              color: '#fff',
              fontFamily: 'Sans700',
            }}
          >
            top 17% of {zodiac}
          </span>{' '}
          people make decisions with their{' '}
          <span
            style={{
              color: '#E1413E',
              fontFamily: 'Sans700',
            }}
          >
            heart and head.
          </span>{' '}
          Using both in equal measure is the key to feeling harmonious in your relationships.
        </Box>
      );
    }
  }
}

function Step6Additional() {
  const { setStep, quizData } = useQuizState();

  return (
    <QuizStepBackgroundWrapper withoutBottom fullHeight>
      <QuizContainer>
        <Box
          sx={{
            height: '100dvh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              '& img': {
                width: '100%',
              },
            }}
          >
            <img src={q6_image} alt="heart"></img>
          </Box>
          <Typography
            sx={{
              fontFamily: 'Kalnia500',
              fontSize: '32px',
              color: '#fff',
              textAlign: 'center',
            }}
          >
            Wonderful!
          </Typography>
          {getText(quizData.decisions, quizData.zodiac)}
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <QuizButton onClick={() => setStep((prev) => prev - 1)} variant={ButtonType.BLACK} text="Back"></QuizButton>
            <QuizButton onClick={() => setStep((prev) => prev + 1)} variant={ButtonType.RED} text="Next"></QuizButton>
          </Box>
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step6Additional;
