import { useState, ReactNode, createContext, FC, useEffect, useRef } from 'react';

import { DEFAUL_QUIZ_DATA, QuizContextType, QuizData } from './QuizProvider.types';
import { LocationConfig, getLocationData } from '../api/requests';

export const QuizContext = createContext<QuizContextType | null>(null);

export const QuizProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [step, setStep] = useState(1);
  const [quizData, setQuizData] = useState<QuizData>(DEFAUL_QUIZ_DATA);
  const [userLocation, setUserLocation] = useState<LocationConfig | null>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    getLocationData()
      .then((response) => {
        setUserLocation(response);
      })
      .catch(console.error);
  }, []);
  // console.log('quizData', quizData);

  const value: QuizContextType = {
    step,
    setStep,
    quizData,
    setQuizData,
    userLocation,
    formRef,
  };

  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
};
