import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider.hooks';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import { getHourOptionList, getMinuteOptionList } from '../../../utils/common';
import QuizSelect from '../../common/QuizSelect';

function Step5() {
  const { setStep, quizData, setQuizData } = useQuizState();
  const Minutes = getMinuteOptionList();
  const Hours = getHourOptionList();

  const isDisabled = useMemo(() => {
    return !(quizData.birthTime.hour !== '' && quizData.birthTime.minute !== '');
  }, [quizData.birthTime]);

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          setStep((prev) => prev - 1);
        }}
        stepNumber={5}
        stepsNumber={8}
      ></QuizHeader>
      <QuizQuestion text="At what time were you born?"></QuizQuestion>
      <QuizContainer>
        <Typography
          sx={{
            fontFamily: 'Sans400',
            fontSize: '20px',
            color: '#717889',
            marginBottom: '32px',
            marginTop: '-16px',
          }}
        >
          We use NASA data to identify the exact position of the planets in the sky at the time of your partner's birth.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            marginBottom: '32px',
          }}
        >
          <QuizSelect
            label="HH"
            values={Hours}
            value={quizData.birthTime.hour}
            onChange={(e) => {
              setQuizData((prev) => ({
                ...prev,
                birthTime: {
                  ...prev.birthTime,
                  hour: e.target.value,
                },
              }));
            }}
          />
          <QuizSelect
            label="MM"
            values={Minutes}
            value={quizData.birthTime.minute}
            onChange={(e) => {
              setQuizData((prev) => ({
                ...prev,
                birthTime: {
                  ...prev.birthTime,
                  minute: e.target.value,
                },
              }));
            }}
          />
          <Box
            sx={{
              minWidth: '122px',
              background: '#fff',
              borderRadius: '12px',
              display: 'flex',
              '& div': {
                width: '100%',
                cursor: 'pointer',
                margin: '4px',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Sans700',
                fontSize: '20px',
              },
            }}
          >
            <Box
              sx={{
                background: quizData.birthTime.timeFormat === 'AM' ? '#E1413E' : 'transparent',
                color: quizData.birthTime.timeFormat === 'AM' ? '#fff' : '#01091C',
              }}
              onClick={() => {
                setQuizData((prev) => ({
                  ...prev,
                  birthTime: {
                    ...prev.birthTime,
                    timeFormat: 'AM',
                  },
                }));
              }}
            >
              AM
            </Box>
            <Box
              sx={{
                background: quizData.birthTime.timeFormat === 'PM' ? '#E1413E' : 'transparent',
                color: quizData.birthTime.timeFormat === 'PM' ? '#fff' : '#01091C',
              }}
              onClick={() => {
                setQuizData((prev) => ({
                  ...prev,
                  birthTime: {
                    ...prev.birthTime,
                    timeFormat: 'PM',
                  },
                }));
              }}
            >
              PM
            </Box>
          </Box>
        </Box>
        <QuizButton
          disabled={isDisabled}
          onClick={() => {
            setStep((prev) => prev + 2);
          }}
          variant={ButtonType.RED}
          text="Continue"
        ></QuizButton>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step5;
