import React from 'react';

import Header from './components/Header';
import NumberOfUsers from './components/NumberOfUsers';
import UnderstandYourself from './components/UnderstandYourself';
import Footer from './components/Footer';
import Reviews from './components/Reviews';

function Main() {
  return (
    <>
      <Header></Header>
      <NumberOfUsers></NumberOfUsers>
      <UnderstandYourself></UnderstandYourself>
      <Reviews></Reviews>
      <Footer></Footer>
    </>
  );
}

export default Main;
