import React, { FormEvent, useState } from 'react';
import { Box, CircularProgress, Modal, Typography } from '@mui/material';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import close from '../../../assets/svg/close.svg';
import {
  CardCvvElement,
  CardMonthElement,
  CardNumberElement,
  CardYearElement,
  useRecurly,
} from '@recurly/react-recurly';
import { useSelectedPlan } from '../hooks';
import { createSubscriptionNoUser } from '../../../api/requests';
import { useQuizState } from '../../../providers/QuizProvider.hooks';
import { EMAIL_REGEX } from '../../../utils/constants';

function CardPaymentModal({
  isCardPaymentModalOpen,
  setCardPaymentModalOpen,
}: {
  isCardPaymentModalOpen: boolean;
  setCardPaymentModalOpen: (s: boolean) => void;
}) {
  const { userLocation, formRef, quizData } = useQuizState();
  const { selectedPlan } = useSelectedPlan();
  const [inputEmail, setInputEmail] = useState(quizData.email);

  const [isValidCardNumber, setValidCardNumber] = useState(false);
  const [isValidCardMonth, setValidCardMonth] = useState(false);
  const [isValidCardYear, setValidCardYear] = useState(false);
  const [isValidCardCvv, setValidCardCvv] = useState(false);
  const [isValidFirstName, setValidFirstName] = useState(false);
  const [isValidLastName, setValidLastName] = useState(false);
  const [isValidEmail, setValidEmail] = useState(Boolean(quizData.email.toLowerCase().match(EMAIL_REGEX)));

  const [showErrors, setShowErros] = useState(false);
  const [paymentFormError, setPaymentFormError] = useState<string | null>(null);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const recurly = useRecurly();

  const inputStyle = {
    fontColor: '#20263F',
    fontSize: '16px',
    fontFamily: 'Source Sans Pro',
    lineHeight: 'normal',
    fontWeight: '500',
    backgroundColor: 'white',
  };

  const handleSubmitClick = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isPaymentLoading) return;

    const isValid =
      isValidEmail &&
      isValidFirstName &&
      isValidLastName &&
      isValidCardNumber &&
      isValidCardMonth &&
      isValidCardYear &&
      isValidCardCvv;
    if (isValid) {
      handleSubmitForm(event);
    } else {
      setShowErros(true);
    }
  };

  function handleSubmitForm(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (formRef.current) {
      setPaymentLoading(true);
      recurly.token(formRef.current, (err, token) => {
        if (err) {
          console.log('err', err);
          setPaymentFormError(err.message);
          setPaymentLoading(false);
        } else {
          console.log('token', token);
          createSubscriptionNoUser({
            email: inputEmail,
            plan_id: selectedPlan?.planId,
            token_id: token.id,
            location: userLocation,
          })
            .then((response) => {
              console.log('createSubscriptionNoUser  response', response);
              if ('error' in response) {
                console.log('response.error', response.error);
                setPaymentFormError(response.error);
                setPaymentLoading(false);
              }
              if ('id' in response) {
                const redirectUrl = `${process.env.REACT_APP_ACTIVATE_SUBSCRIPTION_REDIRECT_URL}${response.plan.code}/${response.account.code}/${response.uuid}`;
                window.open(redirectUrl, '_self');
              }
            })
            .catch((e) => {
              console.error('error', e);
            })
            .finally(() => {
              setPaymentLoading(false);
            });
        }
      });
    }
  }

  return (
    <Modal
      open={isCardPaymentModalOpen}
      onClose={() => {
        setCardPaymentModalOpen(false);
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            width: 'calc(100% - 40px)',
            maxWidth: '460px',
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: '20px',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <img
              onClick={() => {
                setCardPaymentModalOpen(false);
              }}
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                cursor: 'pointer',
              }}
              src={close}
              alt="close"
            ></img>
            <Typography
              sx={{
                color: '#20263F',
                fontFamily: 'Kalnia600',
                fontSize: '20px',
                padding: '15px',
                borderBottom: '1px solid rgba(143, 154, 203, 0.20)',
              }}
            >
              Payment
            </Typography>
            <Box
              sx={{
                padding: '0 20px 20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <form
                onSubmit={handleSubmitClick}
                ref={formRef}
                onChange={() => {
                  setShowErros(false);
                }}
              >
                <Box
                  sx={{
                    margin: '20px 0',
                    '& .wrapper': {
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    },
                    '& input': {
                      width: 'calc(100%)',
                      borderRadius: '8px',
                      border: '1px solid rgba(143, 154, 203, 0.20)',
                      paddingLeft: '15px',
                      height: '48px',
                      boxSizing: 'border-box',
                      color: '#20263F',
                      fontFamily: 'Sans400',
                      fontSize: '14px',
                      outline: 'none',
                      '&:hover': {
                        outline: 'none',
                      },
                    },
                    '& .input-title': {
                      color: '#20263F',
                      fontFamily: 'Sans700',
                      fontSize: '14px',
                      textAlign: 'left',
                      marginBottom: '5px',
                    },
                    '& .error': {
                      width: '100%',
                      color: '#FF344C',
                      fontFamily: 'Sans400',
                      fontSize: '10px',
                      textAlign: 'left',
                      marginTop: '5px',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <Box className="wrapper">
                      <Typography className="input-title">First name</Typography>
                      <input
                        type="text"
                        data-recurly="first_name"
                        onChange={(event) => {
                          setValidFirstName(event.target.value.trim() !== '');
                        }}
                      ></input>
                      {showErrors && !isValidFirstName && <Typography className="error">Fill in the field</Typography>}
                    </Box>
                    <Box className="wrapper">
                      <Typography className="input-title">Last name</Typography>
                      <input
                        type="text"
                        data-recurly="last_name"
                        onChange={(event) => {
                          setValidLastName(event.target.value.trim() !== '');
                        }}
                      ></input>
                      {showErrors && !isValidLastName && <Typography className="error">Fill in the field</Typography>}
                    </Box>
                  </Box>
                  <Box className="wrapper" marginTop="10px">
                    <Typography className="input-title">Email</Typography>
                    <input
                      type="email"
                      value={inputEmail}
                      onChange={(event) => {
                        const isValid = event.target.value.toLowerCase().match(EMAIL_REGEX);
                        setValidEmail(Boolean(isValid));
                        setInputEmail(event.target.value);
                      }}
                    ></input>
                    {showErrors && !isValidEmail && (
                      <Typography className="error">Please enter a valid email</Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    background: 'rgba(143, 154, 203, 0.20)',
                    borderRadius: '12px',
                    marginBottom: '20px',
                    padding: '20px',
                    '& .input-wrapper': {
                      background: 'white',
                      borderRadius: '8px',
                      maxHeight: '48px',
                      paddingLeft: '15px',
                      '& iframe': {
                        maxHeight: '48px',
                      },
                    },
                    '& .input-title': {
                      color: '#20263F',
                      fontFamily: 'Sans700',
                      fontSize: '14px',
                      textAlign: 'left',
                      marginBottom: '5px',
                    },
                    '& .error': {
                      width: '100%',
                      color: '#FF344C',
                      fontFamily: 'Sans400',
                      fontSize: '10px',
                      textAlign: 'left',
                      marginTop: '5px',
                    },
                  }}
                >
                  <Typography className="input-title">Card number</Typography>
                  <Box className="input-wrapper">
                    <CardNumberElement
                      style={{
                        ...inputStyle,
                        placeholder: {
                          content: 'XXXX XXXX XXXX XXXX',
                          color: 'rgba(47, 42, 42, 0.2)',
                        },
                      }}
                      inputType={'text'}
                      onChange={(event) => {
                        setValidCardNumber(event.valid);
                      }}
                    />
                  </Box>
                  {showErrors && !isValidCardNumber && (
                    <Typography className="error">Your card number is incomplete</Typography>
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      gap: '20px',
                      marginTop: '15px',
                    }}
                  >
                    <Box>
                      <Typography className="input-title">Expires on</Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '9px',
                        }}
                      >
                        <Box>
                          <Box className="input-wrapper">
                            <CardMonthElement
                              style={{
                                ...inputStyle,
                                placeholder: {
                                  content: 'MM',
                                  color: 'rgba(47, 42, 42, 0.2)',
                                },
                              }}
                              onChange={(event) => {
                                setValidCardMonth(event.valid);
                              }}
                            />
                          </Box>
                        </Box>
                        <Box className="input-wrapper">
                          <CardYearElement
                            style={{
                              ...inputStyle,
                              placeholder: {
                                content: 'YY',
                                color: 'rgba(47, 42, 42, 0.2)',
                              },
                            }}
                            onChange={(event) => {
                              setValidCardYear(event.valid);
                            }}
                          />
                        </Box>
                      </Box>
                      {showErrors && (!isValidCardMonth || !isValidCardYear) && (
                        <Typography className="error" style={{}}>
                          Your card is expired
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography className="input-title">CVC</Typography>
                      <Box className="input-wrapper">
                        <CardCvvElement
                          style={{
                            ...inputStyle,
                            placeholder: {
                              content: '•••',
                              color: 'rgba(47, 42, 42, 0.2)',
                            },
                          }}
                          onChange={(event) => {
                            setValidCardCvv(event.valid);
                          }}
                        />
                      </Box>
                      {showErrors && !isValidCardCvv && (
                        <Typography className="error">Your card’s security code is incompete</Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
                {paymentFormError && (
                  <Typography
                    sx={{
                      marginBottom: '20px',
                      color: '#FF344C',
                      fontFamily: 'Sans500',
                      fontSize: '14px',
                    }}
                  >
                    {paymentFormError}
                  </Typography>
                )}

                <QuizButton
                  type="submit"
                  onClick={() => {}}
                  variant={ButtonType.RED}
                  text={isPaymentLoading ? '' : 'Proceed payment'}
                >
                  {isPaymentLoading ? <CircularProgress style={{ color: 'white' }}></CircularProgress> : undefined}
                </QuizButton>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default CardPaymentModal;
