import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import {
  Email,
  Step1,
  Step2,
  Step2Additional,
  Step3,
  Step4,
  Step5,
  Step5No,
  Step5Yes,
  Step6,
  Step6Additional,
  Step7,
  Step8,
} from './Steps';
import { useQuizState } from '../../providers/QuizProvider.hooks';
import q2_image from '../../assets/images/quiz/q2_image.webp';
import q5_image from '../../assets/images/quiz/q5_image.webp';
import q6_image from '../../assets/images/quiz/q6_image.webp';
import web_rigth_palm from '../../assets/images/quiz/web_right_palm.webp';
import web_left_palm from '../../assets/images/quiz/web_left_palm.webp';

const getStep = (stepNumber: number) => {
  switch (stepNumber) {
    case 1: {
      return <Step1 />;
    }
    case 2: {
      return <Step2 />;
    }
    case 3: {
      return <Step2Additional />;
    }
    case 4: {
      return <Step3 />;
    }
    case 5: {
      return <Step4 />;
    }
    case 6: {
      return <Step5 />;
    }
    case 7: {
      return <Step5Yes />;
    }
    case 8: {
      return <Step5No />;
    }
    case 9: {
      return <Step6 />;
    }
    case 10: {
      return <Step6Additional />;
    }
    case 11: {
      return <Step7 />;
    }
    case 12: {
      return <Step8 />;
    }
    case 13: {
      return <Email />;
    }
  }
};

function Quiz() {
  const { step } = useQuizState();

  useEffect(() => {
    const images = [q2_image, q5_image, q6_image, web_rigth_palm, web_left_palm];

    images.forEach((path) => {
      const img = new Image();
      img.src = path;
    });
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: '#01091C',
        minHeight: '100dvh',
        height: '100%',
      }}
    >
      {getStep(step)}
    </Box>
  );
}

export default Quiz;
