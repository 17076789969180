import { Box, Typography } from '@mui/material';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider.hooks';
import { Gender } from '../../../providers/QuizProvider.types';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Step2() {
  const { setStep, setQuizData } = useQuizState();
  const handleClick = (type: Gender) => {
    setQuizData((prev) => ({
      ...prev,
      gender: type,
    }));
    setStep((prev) => prev + 1);
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          setStep((prev) => prev - 2);
        }}
        stepNumber={3}
        stepsNumber={8}
      ></QuizHeader>
      <QuizQuestion text="What’s your gender?"></QuizQuestion>
      <QuizContainer>
        <Typography
          sx={{
            fontFamily: 'Sans400',
            fontSize: '20px',
            color: '#717889',
            marginBottom: '32px',
            marginTop: '-16px',
          }}
        >
          This highlights the duality of your masculine and feminine energies.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <QuizButton onClick={() => handleClick(Gender.MALE)} variant={ButtonType.WHITE} text="Male"></QuizButton>
          <QuizButton onClick={() => handleClick(Gender.FEMALE)} variant={ButtonType.WHITE} text="Female"></QuizButton>
          <QuizButton
            onClick={() => handleClick(Gender.NON_BINARY)}
            variant={ButtonType.WHITE}
            text="Non-binary"
          ></QuizButton>
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step2;
