import { PropsWithChildren } from 'react';

import { RecurlyOptions } from '@recurly/recurly-js';
import { ElementsProps } from '@recurly/react-recurly';

export type RecurlyProviderType = RecurlyOptions & PropsWithChildren;

export type ElementType = ElementsProps & PropsWithChildren;

export interface SubscribeRecurlySuccess {
  id: string;
  uuid: string;
  account: {
    code: string;
  };
  plan: {
    code: string;
  };
}

export interface SubscribeRecurlyError {
  error: string;
}

export interface SubscribeRecurlySecure3d {
  actionTokenId: string;
  code: string;
}

export interface LocationConfig {
  ip: string | null;
  country: string | null;
}

export async function fetcher<JSON = unknown>(input: RequestInfo, init?: RequestInit): Promise<JSON> {
  const response: Response = await fetch(input, init);

  if (!response.ok || response.status !== 200) {
    return Promise.reject(response);
  }

  // if the server replies, there's always some data in json
  // if there's a network error, it will throw at the previous line
  const data = await response.json();

  // response.ok is true when res.status is 2xx
  // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
  if (response.ok) {
    return data;
  }

  throw new FetchError({
    message: response.statusText,
    response,
    data,
  });
}

export class FetchError extends Error {
  response: Response;
  data: {
    message: string;
  };

  constructor({
    message,
    response,
    data,
  }: {
    message: string;
    response: Response;
    data: {
      message: string;
    };
  }) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = 'FetchError';
    this.response = response;
    this.data = data ?? { message: message };
  }
}


const searchParams = new URLSearchParams(document.location.search);

const getParamByKey = (key: string) => searchParams.get(key);

export const isBrowser: boolean = typeof window !== 'undefined';

let referer: any = null

if (isBrowser) {
  try {
    const refererUrl = new URL(document.referrer)

    if (refererUrl.host !== document.location.host) {
      localStorage.setItem('_ref', refererUrl.toString());
    }
  } catch (e) {
  }

  referer = localStorage.getItem('_ref')
}
const hasRedirectDomain = new URLSearchParams(window.location.search).get('redirectDomain');

export function filterFalsyValues(object: Record<string, string | null>) {
  try {
    const keys = Object.keys(object);
    keys.forEach((key) => {
      if (object[key] == null || object[key] === '') {
        delete object[key];
      }
    });
    return object;
  } catch (e) {
    console.error('[removeFalsyValuesFromObject error]: ', e);
  }
  return object;
}

export async function createSubscriptionNoUser({
  email,
  plan_id,
  token_id,
  location
}: {
  email: string, plan_id: string | undefined, token_id: string, location: LocationConfig | null
}): Promise<SubscribeRecurlySuccess | SubscribeRecurlyError | SubscribeRecurlySecure3d> {

  const attribution = {
    campaign_id: getParamByKey('campaign_id'),
    campaign_name: getParamByKey('campaign_name'),
    adset_id: getParamByKey('adset_id'),
    adset_name: getParamByKey('adset_name'),
    ad_id: getParamByKey('ad_id'),
    media_source: getParamByKey('media_source'),
    config: getParamByKey('config'),
    country_code: location?.country || '',
    ad_name: getParamByKey('ad_name'),
    fbc: getParamByKey('_fbc'),
    fbp: getParamByKey('_fbp'),
    referer: referer,
    event_source_url: hasRedirectDomain ?? window.location.href,
    client_user_agent: navigator.userAgent,
    client_ip_address: location?.ip || '',
  }
  const newAttribution = filterFalsyValues(attribution);

  return fetcher(`${process.env.REACT_APP_RECURLY_URI}/create-subscription-no-user`, {
    method: 'POST',
    body: JSON.stringify({
      plan_id,
      token_id,
      email,
      attribution: newAttribution ?? {},
    }),

    headers: {
      'Content-Type': 'text/plain',
    },
  });
}

export interface LocationConfig {
  ip: string | null;
  country: string | null;
}

export const getLocationData = async (): Promise<LocationConfig> =>
  fetcher(`${window.location.origin}/remote-addr`, {
    method: 'GET',
  });
