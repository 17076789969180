import { Box } from '@mui/material';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider.hooks';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';

function Step6() {
  const { setStep, setQuizData } = useQuizState();

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          setStep((prev) => prev - 3);
        }}
        stepNumber={6}
        stepsNumber={8}
      ></QuizHeader>
      <QuizQuestion text="Do you make decisions with your head or your heart?"></QuizQuestion>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <QuizButton
            onClick={() => {
              setQuizData((prev) => ({ ...prev, decisions: 'Heart' }));
              setStep((prev) => prev + 1);
            }}
            variant={ButtonType.WHITE}
            text="Heart"
          ></QuizButton>
          <QuizButton
            onClick={() => {
              setQuizData((prev) => ({ ...prev, decisions: 'Head' }));
              setStep((prev) => prev + 1);
            }}
            variant={ButtonType.WHITE}
            text="Head"
          ></QuizButton>
          <QuizButton
            onClick={() => {
              setQuizData((prev) => ({ ...prev, decisions: 'Both' }));
              setStep((prev) => prev + 1);
            }}
            variant={ButtonType.WHITE}
            text="Both"
          ></QuizButton>
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step6;
