import { useMemo } from 'react';
import { PLANS } from '../../../utils/constants';
import { endTrial } from '../../../utils/common';
import { useQuizState } from '../../../providers/QuizProvider.hooks';

export function useSelectedPlan() {
  const { quizData } = useQuizState();

  const selectedPlan = useMemo(() => {
    return PLANS.find((plan) => plan.name === quizData.selectedPlan);
  }, [quizData.selectedPlan]);

  const trialEnd = endTrial(
    selectedPlan?.trialUnit || '',
    selectedPlan?.trialLenght || 0
  );

  const montlyPrice = useMemo(() => {
    if (selectedPlan)
      return (selectedPlan?.fullPrice / selectedPlan?.intervalLength).toFixed(
        2
      );
  }, [selectedPlan]);

  return {
    selectedPlan,
    montlyPrice,
    trialEnd,
  };
}
