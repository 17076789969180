import { Box } from '@mui/material';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider.hooks';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import { useMemo } from 'react';
import QuizSelect from '../../common/QuizSelect';
import { getDaysOption, getMonthsOption, getYearsOption, getZodiacSign } from '../../../utils/common';
import { getDaysInMonth } from 'date-fns';

function Step4() {
  const { setStep, quizData, setQuizData } = useQuizState();
  const Months = getMonthsOption();
  const Days = useMemo(() => {
    const result = getDaysOption(
      getDaysInMonth(new Date(Number(quizData.birthDate.year), Number(quizData.birthDate.month)))
    );
    if (result.length < Number(quizData.birthDate.day)) {
      setQuizData((prev) => ({
        ...prev,
        birthDate: {
          ...prev.birthDate,
          day: '',
        },
      }));
    }
    return result;
  }, [quizData.birthDate, setQuizData]);
  const Years = getYearsOption();

  const isDisabled = useMemo(() => {
    return !(quizData.birthDate.month !== '' && quizData.birthDate.day !== '' && quizData.birthDate.year !== '');
  }, [quizData.birthDate]);

  const handleContinue = () => {
    const zodiac = getZodiacSign(Number(quizData.birthDate.month) + 1, Number(quizData.birthDate.day));
    setQuizData((prev) => ({
      ...prev,
      zodiac,
    }));
    setStep((prev) => prev + 1);
  };

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          setStep((prev) => prev - 1);
        }}
        stepNumber={4}
        stepsNumber={8}
      ></QuizHeader>
      <QuizQuestion text="What’s your date of birth?"></QuizQuestion>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginBottom: '32px',
          }}
        >
          <QuizSelect
            label="Month"
            values={Months}
            value={quizData.birthDate.month}
            onChange={(e) => {
              setQuizData((prev) => ({
                ...prev,
                birthDate: {
                  ...prev.birthDate,
                  month: e.target.value,
                },
              }));
            }}
          />

          <QuizSelect
            label="Day"
            values={Days}
            value={quizData.birthDate.day}
            onChange={(e) => {
              setQuizData((prev) => ({
                ...prev,
                birthDate: {
                  ...prev.birthDate,
                  day: e.target.value,
                },
              }));
            }}
          />

          <QuizSelect
            label="Year"
            values={Years}
            value={quizData.birthDate.year}
            onChange={(e) => {
              setQuizData((prev) => ({
                ...prev,
                birthDate: {
                  ...prev.birthDate,
                  year: e.target.value,
                },
              }));
            }}
          />
        </Box>
        <QuizButton
          disabled={isDisabled}
          onClick={handleContinue}
          variant={ButtonType.RED}
          text="Continue"
        ></QuizButton>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step4;
