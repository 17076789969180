import { Box, Typography } from '@mui/material';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider.hooks';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import q2_image from '../../../assets/images/quiz/q2_image.webp';

function Step2Additional() {
  const { setStep } = useQuizState();

  return (
    <QuizStepBackgroundWrapper withoutBottom fullHeight>
      <QuizContainer>
        <Box
          sx={{
            height: '100dvh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              '& img': {
                width: '100%',
              },
            }}
          >
            <img src={q2_image} alt="heart"></img>
          </Box>
          <Typography
            sx={{
              fontFamily: 'Kalnia500',
              fontSize: '32px',
              color: '#fff',
              textAlign: 'center',
            }}
          >
            Great! You just set your first goal!
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Sans400',
              fontSize: '20px',
              color: '#717889',
              textAlign: 'center',
              margin: '16px auto 24px',
              maxWidth: '350px',
            }}
          >
            Let's keep going so we can get to know you better.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <QuizButton onClick={() => setStep((prev) => prev - 1)} variant={ButtonType.BLACK} text="Back"></QuizButton>
            <QuizButton onClick={() => setStep((prev) => prev + 1)} variant={ButtonType.RED} text="Next"></QuizButton>
          </Box>
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step2Additional;
