import { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import QuizQuestion from '../../common/QuizQuestion';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider.hooks';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../App';
import { EMAIL_REGEX } from '../../../utils/constants';

function Email() {
  const { quizData, setQuizData } = useQuizState();
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const isDisabled = useMemo(() => {
    return quizData.email === '';
  }, [quizData.email]);

  const handleContinue = () => {
    const isValid = quizData.email.toLowerCase().match(EMAIL_REGEX);
    if (isValid) {
      navigate(Routes.SUBSCRIPTION);
    } else {
      setError(true);
    }
  };

  return (
    <QuizStepBackgroundWrapper withoutBottom>
      <QuizContainer>
        <Box
          sx={{
            minHeight: '100dvh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              '@media (max-width: 500px)': {
                '& p': {
                  fontSize: '28px',
                },
              },
            }}
          >
            <QuizQuestion
              textAlign="center"
              text="Enter your email to see how you can Find peace of mind and know yourself better with us"
            ></QuizQuestion>
          </Box>
          <Box
            sx={{
              padding: '0 20px',
              '& input': {
                width: '100%',
                textAlign: 'center',
                color: '#01091C',
                fontFamily: 'Sans700',
                fontSize: '20px',
                height: '66px',
                border: 'none',
                borderRadius: '12px',
                '&:focus-visible': {
                  border: 'none',
                },
              },
              '& input::placeholder': {
                color: '#717889',
                fontFamily: 'Sans400',
                fontSize: '20px',
              },
            }}
          >
            <input
              placeholder="Your email"
              type="text"
              value={quizData.email}
              onChange={(e) => {
                setError(false);
                setQuizData((prev) => ({ ...prev, email: e.target.value }));
              }}
            ></input>
          </Box>
          {error && (
            <Box
              sx={{
                backgroundColor: '#E1413E',
                borderRadius: '10px',
                color: '#fff',
                fontFamily: 'Sans400',
                fontSize: '14px',
                padding: '8px 16px',
                margin: '8px auto 0px',
              }}
            >
              Wrong email format
            </Box>
          )}
          <QuizContainer>
            <Typography
              sx={{
                color: '#717889',
                fontFamily: 'Sans400',
                fontSize: '16px',
                textAlign: 'center',
                margin: '32px 0',
              }}
            >
              *We do not share any personal information. We'll email you a copy of your program for convenient access.
            </Typography>
            <Box marginBottom={'30px'}>
              <QuizButton
                disabled={isDisabled}
                onClick={handleContinue}
                variant={ButtonType.RED}
                text="Continue"
              ></QuizButton>
            </Box>
          </QuizContainer>
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Email;
