import { APP_CONSTANTS } from '../../utils/constants';

import * as S from './styles';

const Privacy = (): JSX.Element => (
  <S.TermsContent>
    <S.TermsHeader>Privacy Policy</S.TermsHeader>
    <S.TermsDate>Last Updated: December 27, 2023</S.TermsDate>
    <S.TermsBody>
      <p>
        Please read this Privacy Policy carefully to understand our policies and practices regarding your Personal Data
        (as defined below) and how we will treat it.
      </p>
      <p>
        This Privacy Policy explains, how we and affiliated companies (collectively, "{APP_CONSTANTS.APP_SHORT_NAME}" or
        "we", "us", "our") collect and process personal data, when end users (“you” or “your”) use games and other
        applications, as well as related services (together the “Services” or “apps”) provided and managed by{' '}
        {APP_CONSTANTS.APP_SHORT_NAME}.
      </p>
      <p>The data controller is {APP_CONSTANTS.APP_NAME + ', ' + APP_CONSTANTS.ADDRESS}</p>
      <li>
        <span className="main-list">WHAT INFORMATION DO WE COLLECT?</span>
        <ol className="secondary">
          <p>
            When you use the Services, we collect certain personally identifiable information that can be used to
            identify you (“Personal Data”). Our Services are designed to avoid collecting information such as your name,
            address, email address or other similar contact data. However, in certain cases we may process such data, if
            you provide them to us (for example, if you resort to our support team).
          </p>
          <p>We process the following information:</p>
          <p>
            <b>Device Information</b> – these data include the operating system of your device (device type), your
            mobile device identifiers (device ID, advertising ID), resolution, version of the application, language,
            time zone, as well as IP address.
          </p>
          <p>
            <b>Information about Your Use of the Services</b> – these data include information about your activity
            within the Services, such as achievements within the Services, completed levels and actions taken within the
            Services, in-app purchases data (payment identification number, the type and amount of a product, its price
            in your local currency, date and time of the purchase), subscriptions, advertising that you click on.
          </p>
          <p>
            <b>Location Information</b> – these data include information about your country, state and city of your
            location.
          </p>
          <p>
            <b>User Inquiries Information</b> – these data includes all information you provide to us when addressing
            our support team or communicate with us in any other way.
          </p>
        </ol>
      </li>
      <li>
        <span className="main-list">DO WE PROCESS YOU PAYMENT INFORMATION?</span>
        <ol className="secondary">
          <p>
            Our Services include in-app purchases, e.g. subscriptions. If you want to make an in-app purchase, you may
            do this with the help of in-app payment system provided by the Platform (either Google Play managed by
            Google or AppStore managed by Apple) and integrated in the Services. The in-app payment system is managed by
            the Platform administration or its authorized partner. Under no circumstances does{' '}
            {APP_CONSTANTS.APP_SHORT_NAME} collect or process any information related to your payment instrument, such
            as bank card number, its validity term or your name as written on it.
          </p>
        </ol>
      </li>
      <li>
        <span className="main-list">
          DO WE PROVIDE THIRD PARTIES WITH PERSONAL DATA OR GET PERSONAL DATA FROM THIRD PARTIES?
        </span>
        <ol className="secondary">
          <p>
            Yes. For the purposes of analytics and advertising, we use services and solutions provided by third parties.
            Such parties process data on our behalf and the respective technical solutions represent a part of the
            Services. Any transfer of your Personal Data is based on a written agreement, and we make sure such a third
            party complies with the applicable data protection regulations. Such third parties process Personal Data in
            accordance with their own privacy policies.
          </p>
          <p>
            In certain cases we may transfer information about you to other third parties (for example, independent
            personal data controllers), if we are obliged or allowed to do so by applicable laws. If it is the case, we
            transfer such information while adhering to applicable personal data protection laws.
          </p>
          <p>
            If {APP_CONSTANTS.APP_SHORT_NAME} is involved, whether in whole or in part, in a merger, sale, acquisition,
            divestiture, restructuring, reorganization, dissolution, bankruptcy or other change of ownership or control,
            we may transfer Personal Data, together with business assets, to another company. In this case the
            respective notification will be available on the web-page of the Privacy Policy.
          </p>

          <p>We also may transfer your Personal Data to a third party in the following cases:</p>
          <ul className="sub-secondary">
            <li>
              if we need so to protect legal rights of {APP_CONSTANTS.APP_SHORT_NAME}, our end users, business parties
              or other interested parties;
            </li>
            <li> if we need so to enforce our agreements with you;</li>
            <li>
              if we need so to investigate, prevent, or take other action regarding illegal activity, suspected fraud or
              other wrongdoing.
            </li>
          </ul>
        </ol>
      </li>
      <li>
        <span className="main-list">FOR WHAT PURPOSES DO WE PROCESS PERSONAL DATA?</span>
        <ol className="secondary">
          <p>We process Personal Data for the following purposes:</p>
          <ul className="sub-secondary">
            <li>
              to provide and maintain the Services, make the Services available to you if you switch devices or
              temporarily stop using the Services, as well as to manage everyday business activity related to the
              Services.
            </li>
            <li>
              to manage and protect the Services (e.g. to collect analytics, test, support end users, report illegal
              activity, etc.).
            </li>
            <li>to communicate with you with regard to your inquiries submitted to our support team.</li>
            <li>
              to analyze how you use the Services in order to plan further development of the Services, keep the
              Services updated and improve them.
            </li>
            <li>to advertise our Services</li>
          </ul>
        </ol>
      </li>
      <li>
        <span className="main-list">FOR HOW LONG DO WE RETAIN PERSONAL DATA?</span>
        <ol className="secondary">
          <li>
            We retain all Personal Data for as long as you use the Services and 24 months after you stop using the
            Services. If you do not use the Services for 24 continuous months, we erase your Personal Data from our data
            base, as well as request the same erasure from any third party, whom your Personal Data may have been
            transferred to. We may erase your Personal Data earlier if we do not need to process such data anymore.
          </li>
          <li>
            If it is legally required that we retain your Personal Data for longer period for whatever purpose as stated
            in the applicable law, we will retain your Personal Data for the period determined by the law. We will
            immediately erase your Personal Data after such period expires or if the legal duty to retain such Personal
            Data is repealed.
          </li>
          <li>
            We may retain your Personal Data longer only in case such Personal Data have been duly anonymized. After
            anonymization the data are not considered Personal Data, as the end user (the data subject) is no longer
            identifiable.
          </li>
          <li>
            Please, take into account that erasure of Personal Data is irretrievable. After your Personal Data have been
            erased, you will not be able to exercise the right to access, the right to erasure, the right to
            rectification or the right to data portability.
          </li>
        </ol>
      </li>
      <li>
        <span className="main-list">DO WE PROCESS ANY PERSONAL DATA OF CHILDREN?</span>
        <ol className="secondary">
          <p>The Services are intended to be used by end users older than 16 years old.</p>
          <p>We do not use the Services to knowingly solicit data from or market to children under the age of 13.</p>
          <p>
            If you believe that we process Personal Data of a child below 13 years old, please, contact us and we will
            make all reasonable efforts to stop processing such data and erase it as soon as possible.
          </p>
        </ol>
      </li>
      <li>
        <span className="main-list">WHAT RIGHTS DO YOU HAVE WITH REGARD TO YOUR PERSONAL DATA?</span>
        <ol className="secondary">
          <p>
            Under the applicable data protection regulation, if you are a citizen of the EU and fall within the scope of
            a data subject, you are entitled to the following rights: right of access, right to rectification
            (correction), right to erasure (deletion), right to data portability, right to object, right to restriction
            of processing.
          </p>
          <ul className="sub-secondary">
            <li>right of access. You have the right to access your Personal Data, which we process;</li>
            <li>
              right of rectification (correction). You have the right to ask us to replace any inaccurate data about you
              with accurate data. If you think that the data we process are incomplete for the purpose of processing as
              stated in this Privacy Policy, you may ask us to complete your Personal Data accordingly;
            </li>
            <li>right to erasure (deletion). You have the right to ask us to completely delete your Personal Data;</li>
            <li>
              right to object. You have the right to object to processing of your Personal Data for the purpose of
              direct marketing;
            </li>
            <li>
              right to restriction of processing. Please, be informed that this right may be exercised only in a
              situation set forth in Article 18 of General Data Protection Regulation 2016/679;
            </li>
            <li>
              right to data portability. You have the right to ask us to receive the Personal Data concerning you, which
              you have provided to us, in a structured, commonly used and machine-readable format and have the right to
              transmit those data to another controller.
            </li>
          </ul>
        </ol>
      </li>
      <li>
        <span className="main-list">DO WE CONTROL THIRD-PARTY WEB-SITES?</span>
        <ol className="secondary">
          <p>
            This Privacy Policy may contain links to other web-sites that are not operated by us. If you click on such a
            link, you will be directed to that third party's site. We strongly recommend you to review the privacy
            policy and terms of use of every web-site you visit. Under no circumstances do we control third parties’
            web-sites or assume any responsibility for the content, privacy policy, terms of use or practices of any
            third-party web-sites or services.
          </p>
        </ol>
      </li>
      <li>
        <span className="main-list">HOW DO WE ADVERTISE IN THE SERVICES?</span>
        <ol className="secondary">
          <p>
            We show advertisements in the Services with the help of third-party advertising partners. We integrate their
            technologies into the Services, which are then used by our partners to select the advertisements that meet
            your interests in the best way.
          </p>
          <p>
            If you do not want to receive targeted advertisements, please, adjust settings of your device as instructed
            below.
          </p>
        </ol>
      </li>
      <li>
        <span className="main-list">HOW CAN YOU ADJUST PRIVACY SETTINGS OF YOUR DEVICE?</span>
        <ol className="secondary">
          <p>If you want to adjust the privacy settings of your device, please, take the following steps:</p>
          <ol className={'sub-secondary'}>
            <li>
              iOS operating system: enable Limit Ad Tracking on iOS by opening “Settings,” then tapping on “Privacy,”
              then “Advertising”, and sliding the Limit Ad Tracking switch to “On”.
            </li>
            <li>
              Android operating system: opt out of Ads Personalization on Android by opening “Settings”, then tapping
              “Google”, then “Ads”, then enable the switch next to “Opt out of Ads Personalization”.
            </li>
          </ol>
        </ol>
      </li>
      <li>
        <span className="main-list">HOW DO WE COLLECT AND PROCESS DATA FOR ANALYTICAL PURPOSES?</span>
        <ol className="secondary">
          <p>
            In order to analyze various aspects of the Services functioning, we need to collect and process certain
            information with regard to your interaction with the Services. Such information includes your location data,
            information about your device and advertising data. It may also comprise information about errors of the
            Services functioning. For this purpose we use technologies and solutions provided by third parties – our
            analytical partners. We integrate such technologies into the Services and our partners process the data on
            our behalf to provide us with analytics.
          </p>
          <p>
            Processing data for analytical purposes is based on a legitimate interest of {APP_CONSTANTS.APP_SHORT_NAME}{' '}
            – analyzing data about the Services for commercial purposes. Analytics helps us to provide better services
            to users, update and develop the Services. For example, we may use such information in order to fix errors
            of the Services, evaluate demographic composition of our users, determine what features to add to the
            Services or whether we need to translate the Game into another language.
          </p>
        </ol>
      </li>
      <li>
        <span className="main-list">CHANGES TO THIS PRIVACY POLICY</span>
        <ol className="secondary">
          <p>
            We may need to change the Privacy Policy from time to time, and we encourage you to review it at least once
            per month. If you continue to use the Services after changes have been introduced to the Privacy Policy,
            this will mean that you have accepted such changes to the Privacy Policy.
          </p>
          <p>
            In some cases, we may notify you of changes to the Privacy Policy with a prominent notice on the Services
            (for example by way of a notification or pop-up), before the changes become effective and update the
            "effective date" at the top of this Privacy Policy.
          </p>
        </ol>
      </li>
      <li>
        <span className="main-list">CONTACT US</span>
        <ol className="secondary">
          <p>
            If you have any questions about this Privacy Policy, please send us a message at the following address:{' '}
            {APP_CONSTANTS.ADDRESS}
          </p>
        </ol>
      </li>
    </S.TermsBody>
  </S.TermsContent>
);

export default Privacy;
